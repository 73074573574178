export default {
  updateProxyWithSeq: '修改代理（序号：{seq}）',
  updateProxy: '修改代理',
  addProxy: '添加代理',
  addProxyEasy: '添加代理',
  updateSuccess: '修改成功',
  proxyMethod: '代理方式',
  ipCheckService: 'IP查询渠道',
  ipCheckTips1: '1.浏览器窗口的工作台页面展示的IP相关信息，将使用您选择的IP查询渠道进行查询与展示！',
  ipCheckTips2:
    '2.不同的IP查询渠道，可能会存在一定的检测结果差异！若代理检测结果与实际信息不符，可以调整IP查询渠道以确保检测结果的准确性！',
  ipCheckTips3:
    '3.若该窗口的指纹设置中，勾选了基于IP生成：语言、界面语言、时区、地理位置，请确保选择的IP查询渠道的检测结果与实际信息相符，否则会导致上述几处自动生成后产生误差！',
  ipCheckTips4: '（Rola-IP建议使用渠道IP123）',
  proxyType: '代理类型',
  proxyTypeColon: '请选择代理类型',
  ipProtocol: 'IP协议',
  useGlobalProxy: '使用全局设置',
  globalProxyInfo: '使用全局设置的 { proxyType } 代理',
  host: '代理主机',
  hostColon: '请输入代理主机',
  hostPlaceholder: '支持“代理类型://主机:端口号:账号:密码”自动拆分',
  hostTips: '代理类型://主机:端口:账号:密码，填写该格式，光标离开后自动拆分。<br/>若代理主机为IPv6，请将代理主机填写在 [ ] 内！',
  port: '代理端口',
  portPlaceholder: '请输入端口',
  username: '代理账号',
  usernamePlaceholder: '请输入账号（选填）',
  usernamePlaceholder2: '请输入账号',
  password: '代理密码',
  passwordPlaceholder: '请输入密码（选填）',
  passwordPlaceholder2: '请输入密码',
  ipPlaceholder: '请填写默认使用IP地址（如：11.22.33.44）',
  noChangeIp: '只代理当前您填写的IP，若是当前填写IP失效，不会自动寻找可用IP代理！',
  ipHint: '若填写IP，下次打开窗口将优先使用该IP；不填写IP或已填写的IP失效，将使用下方位置信息自动寻找可用的IP！',
  ipTips: '填写IP后，鼠标点击空白处，自动识别并填充下方国家信息',
  country: '国家/地区',
  provicePlaceholder: '请填写州/省编码',
  provice: '州/省',
  cityPlaceholder: '请填写城市编码',
  city: '城市',
  checkProxy: '代理检测',
  httpsProxyMsg1: '[代理检测]',
  httpsProxyMsg2: '请打开网页后测试',
  ipCheckFailedMsg: '代理检测失败，请确认代理IP的有效性，有疑问请联系代理IP商！',
  ipNoChange: '只代理当前IP',
  ipNoChangeTips: '该IP失效不会自动更换IP',
  hostRuleMsg: '请输入主机地址',
  portRuleRequiredMsg: '请输入端口号',
  portRuleNumMsg: '端口号为纯数字',
  portRuleValidMsg: '端口号范围为0-65535，请勿超出此范围',
  usernameRuleMsg: '代理账号长度不超过200位',
  passwordRuleMsg: '代理账号密码长度不超过200位',
  ipRuleMsg1: '勾选“只代理当前IP”时，务必填写IP',
  ipRuleValidMsg: '请填写合法的IP地址',
  proviceRuleMsg: '州/省编码为不超过30个字符的英文字母数字',
  cityRuleMsg1: '请输入正确的城市编码',
  cityRuleMsg2: '城市编码为不超过30个字符的英文字母',
  customize: '自定义代理',
  extractIp: '使用代理IP平台API提取链接提取',
  extractIpDialog: '正在通过API提取链接提取代理IP...',
  extractIpMsg1: '检查上一次的代理IP是否有效...',
  extractIpMsg2: '代理IP可用，直接打开...',
  extractIpMsg3: '代理IP已失效，重新提取代理IP...',
  extractIpMsg4: '检查API提取链接...',
  extractIpMsg5: '代理IP提取链接没有设置，无法提取代理IP。',
  extractIpMsg6: '尝试提取代理IP信息...',
  extractIpMsg7: '正在解析提取结果...',
  extractIpMsg8: '设置成功，正在打开浏览器...',
  extractIpMsg9: '无法提取代理IP，请检查提取链接是否有误。',
  extractIpMsg10: '解析成功，正在设置代理IP...',
  formatError: '请将API返回格式改为text/txt等文本格式后再试...',
  addWhiteList: '正在尝试自动加入白名单...',
  notInWhiteList: '未加入白名单',
  usernameRequiredRule: '请填写代理账号',
  passwordRequiredRule: '请填写代理密码',
  provider: '服务商',
  protocol: '代理协议',
  extractType: '提取方式',
  extractUrlPlaceholder: '请输入提取链接地址',
  extractUrl: '提取链接',
  extractTest: '测试提取',
  whitelistTips: '注意：大部分代理IP平台，需要将本地设备的IP加入其白名单，方可正常访问提取链接，如若出现异常请自行添加白名单！',
  initialPass: '初始密码：',
  extractUrlTips: '获取提取链接方法',
  rolaTips:
    '点击“进入ROLA-IP后台页面”按钮后进入Rola-IP后台，点击菜单项：海外住宅IP->动态住宅IP 或 海外手机IP 或 海外机房IP->动态机房IP，然后点击面板项“API接入”，设置好相关参数以后点击“生成API提取”按钮后，即可生成出代理IP提取链接！',
  extractIpRule: '请选择服务商',
  extractUrlRule: '请填写正确的链接URL',
  extractIpTip: '提取链接尽量设置为每次仅提取出1个IP，避免浪费过多代理平台费用！',
  extractProtocolRule: '请选择代理协议',
  extractIpChange1: '每次打开浏览器窗口都提取新IP',
  extractIpChange2: '上次提取IP失效才提取新IP',
  extractError: '未能请求到数据，请检查API链接是否正确',
  ipCountry: '国家/地区：',
  ipState: '州/省：',
  ipCity: '城市：',
  iplngAndLat: '经度/纬度：',
  ipTimezone: '时区：',
  ipZip: '邮编：',
  ipNotWorking: '网络不通',
  common: '通用',
  noproxy: '直连模式（不设置代理）',
  asocks: 'asocks 动态代理',
  storm: 'storm 动态代理',
  ipideaauto: 'ipidea 动态代理',
  iphtmlauto: 'iphtml 动态代理',
  oxylabsauto: 'oxylabs 动态代理',
  lumauto: 'Luminati(Bright Data) 动态代理',
  lunaproxy: 'lunaproxy 动态代理',
  '922proxy': '922s5 动态代理',
  rolaip: 'rolaip 动态代理',
  skyip: 'skyip 动态代理',
  socks5io: 'socks5io 动态代理',
  kookeey: 'kookeey 动态代理',
  ipfly: 'ipfly 动态代理',
  smart: 'smart 动态代理',
  swift: 'swift 动态代理',
  ssh: 'SSH',
  socks5: 'Socks5',
  http: 'HTTP',
  https: 'HTTPS',
  ip2world: 'ip2world 动态代理',
  skyipTips:
    '点击“进入SKY-IP后台页面“按钮后，进入SKY-IP后台，点击左侧“住宅动态IP”、“手机动态IP”、“机房动态IP”->进入面板后，选择”API接入“，设置好相关参数后，然后点击”生成API链接“按钮后，即可生成提取IP链接！',
  refreshProxyUrl: '刷新URL',
  refreshProxyUrlTips: `1.该功能，仅支持带有“刷新URL功能”的代理；</br>
  2.点击后边刷新图标，将自动执行刷新URL，改变该窗口的IP地址；</br>
  3.多个窗口使用同一个代理信息时，刷新代理IP会同时改变多个窗口的IP地址。`,
  singleAdd: '单个添加代理',
  deleteConfirm: '确认删除选中代理吗？',
  remark: '备注',
  remarkColon: '请输入备注',
  boundBrowsers: '绑定窗口',
  proxyInfo: '代理信息',
  updateRemark: '修改代理备注',
  remarkRule: '代理备注不超过200个字符',
  batchAdd: '批量添加代理',
  batchAddTips: '请至少添加一个代理信息',
  proxyRecommend: '海外代理IP推荐',
  proxyManage: '代理IP管理',
  proxyIp: '代理IP',
  boundBrowsersCount: '已绑定 {count} 窗口',
  batchDetection: '批量代理检测',
  batchDetectionEasy: '批量代理检测',
  noSelection: '请选择代理',
  batchSection: '代理信息：（按右侧说明填写，每行一个）',
  batchSectionTitle: '填写说明：',
  batchDesc1:
    "仅支持http、https、socks5、ssh四种代理类型；相应代理类型后边需要加 :// 字样，如需添加备注，请在代理信息结尾处添加 {'{备注}'} 字样",
  batchDesc2: '不填写代理类型时，将默认设置为为socks5类型',
  batchDesc3: '每行填写一个代理，一次最多添加100个代理',
  batchDesc4: '代理主机支持IPv4、IPv6，IPv6的代理主机需填写在 [] 内',
  batchDesc5: '如需添加刷新URL，请在代理信息结尾处将刷新URL填写在（）内',
  demoTitle: '填写格式举例：',
  demo1: '192.168.1.1:8800&#123;备注&#125(刷新URL);',
  demo2: '192.168.1.1:8800:代理账号:代理密码&#123;备注&#125(刷新URL);',
  demo3: 'socks5://192.168.1.1:8800&#123;备注&#125;(刷新URL);',
  demo4: 'http://[2001:db8:2de:0:0:0:0:e13]:8800&#123;备注&#125;(刷新URL);',
  detectedInfo: '已识别到的代理信息: (共计{count}条)',
  batchFormatError: '每行填写一个代理，一次最多添加100个代理',
  outInfo: '出口IP',
  deleteProxy: '删除代理',
  exportProxy: '导出选中代理',
  exportAllProxy: '导出全部代理',
  exportProxyTitle: '导出代理IP.xlsx',
  exportAllProxyTip: '确定要导出所有项吗？',
  batchExportProxy: '批量导出代理',
  proxyManageRule: '请选择代理IP',
  usedLabel1: '检测到该IP于最近时间',
  usedLabel2: '在本软件中使用过！',
  ipNoUsed: '该IP地址未在本软件中使用过！',
  duplicateCheck: '校验重复',
  maxCheckTimes: '最大重试次数',
  duplicateCheckTips: '打开窗口时，将检测提取的IP是否在本系统中被使用过，如果检测到IP在本系统中使用过，则尝试重新提取IP，最多重新提取5次。',
  maxCheckTips: '代理IP重复时，最多尝试重新提取次数',
  duplicateDetected: '检测到IP重复，正在重新提取...',
  chineseProxy: '国内代理IP推荐',
  visitWebsiteBtn: '进入官网',
  toGlobalSetting: '【进入全局设置】',
  verifyDuplicates: '校验重复项',
  verifyDuplicatesTip1: '与“代理IP管理”列表中所有项进行对比，当代理类型、代理信息与',
  verifyDuplicatesTip2: '某一项完全一致时，则判定该项为重复项，并且不做添加！',
  hasProxyRepeat: '检测到您要添加的代理与“代理IP管理”列表中存在重复，禁止添加！若想添加，请不要勾选“校验重复项”！',
  warningTips: `<p>请大家自行甄别以下平台质量，本软件仅做推荐，出现问题自行负责！</p><p>一旦出现问题，可投诉至我们，我们核实后会做下架处理！</p>`,
  ipfoxy: 'ipfoxy 动态代理',
  dynamicProxy: '全局动态代理',
  lonAndLat: '经度/纬度',
  timeZone: '时区',
  postcode: '语言',
  correct: '更正',
  correctSuccess: '更正成功',
  channel: '平台',
  correctTitle: 'IP识别查询/修改',
  isNotSure: '不正确？',
  batchAddProxyTip: '第{line}行数据，代理账号和代理密码不可以超过200个字符',
  customProxy: '不使用全局设置时，此动态代理仅支持socks5代理类型。',
  bulkPublish: '批量公开给员工账号',
  bulkPublishTip: '是否要将所选的代理IP公开给员工账号？公开后，所选代理IP在员工账号中可见。',
  bulkPublishTip1: '同时允许员工账号对代理IP执行以下操作：',
  bulkUnpublish: '批量取消公开给员工账号',
  bulkUnpublishTip: '是否要取消公开所选的代理IP给员工账号？取消后，所选代理IP在员工账号中不会再展示。',
  bulkUnpublishNotice: '注意：取消公开之后，员工账号中窗口绑定的代理IP将变为直连模式！',
  allowDeleteIP: '允许删除修改',
  publishStaff: '公开给员工账号',
  publishStaffTitle: '勾选此项，将代理IP公开给员工账号，员工账号中可见！',
  publishStaffMsg: '勾选此项，公开给员工账号的代理IP，员工可以进行删除与修改！',
  Publicized: '由{name}公开',
  proxyDleteTip: '您所选择的代理IP包含未授权删除权限的代理IP',
  batchImportAgentTitle: '批量导入代理',
  batchImportAgentTip:
    '说明：此表为导入代理IP时使用，仅将代理IP数据导入至比特浏览器“代理IP管理”列表中，不会执行代理检测，绑定窗口时请自行检测代理是否可用。',
  batchImportAgentTemp: '导入代理IP模板.xlsx',
  batchImportAgentTip2: '单个Excel文件，导入代理IP个数不要大于1000个',
  proxySelectMethod: '请选择代理方式',
  proxyBulkEditing: '批量修改代理IP配置',
  proxyEditTip: '请选择相同“代理方式”的代理IP进行批量修改，您可以在代理IP管理列表中，使用“代理方式”进行搜索！',
  proxyEditRepeatTip: '注意：您当前所修改的代理IP配置，保存成功后，将与代理IP列表中已有的代理IP重复，您是否要继续？',
  batchEditProxyTip:
    '需要对哪项进行修改，请勾选该项最左侧的勾选框，然后再修改该项配置值，届时所有已选择的代理IP该项配置值将同时修改为您最新设置的！',
  usernameTips: `
    <p>支持花括号动态匹配参数，打开窗口时自动替换为匹配值，支持两种匹配方式：</p>
    <p>数字匹配：&#123;num-8&#125;，表示随机生成8位数字，可生成范围为3-12位；</p>
    <p>字符串匹配：&#123;str-5&#125;，表示随机生成5位字符串，可生成范围为3-12位。</p>
    <p>例如：username-a-sTime-&#123;num-5&#125;-end</p>
    <p>结果：username-a-sTime-13245-end</p>`,
  noAvailableProxy: '没有可用的代理IP',
  noUnboundAvailableProxy: '暂无未绑定的代理IP',
  randomlySelectProxy: '随机选择一个代理IP（未绑定中随机）',
  randomlySelectAllProxy: '随机选择一个代理IP（已绑定&未绑定中随机）'
}
