import { AES, enc, lib } from 'crypto-js'

// 随机生成密钥和 IV
function generateRandomKeyAndIV() {
  const key = lib.WordArray.random(32) // 32字节密钥 (AES-256)
  const iv = lib.WordArray.random(16) // 16字节IV (AES-CBC)
  return { key, iv }
}

// 加密数据
export function encryptData(data = '') {
  const { key, iv } = generateRandomKeyAndIV()

  const encrypted = AES.encrypt(data, key, {
    iv: iv
  })

  const ivBytes = enc.Hex.parse(iv.toString())
  const keyBytes = enc.Hex.parse(key.toString())
  const encryptedBytes = enc.Hex.parse(encrypted.ciphertext.toString())

  const combined = ivBytes.concat(keyBytes).concat(encryptedBytes)

  return hexToUint8Array(combined.toString(enc.Hex))
}

// 将 Hex 转换为二进制 Uint8Array
function hexToUint8Array(hex) {
  const length = hex.length / 2
  const uint8Array = new Uint8Array(length)

  for (let i = 0; i < length; i++) {
    uint8Array[i] = parseInt(hex.substr(i * 2, 2), 16) // 每 2 个字符解析为 1 个字节
  }

  return uint8Array
}
