const ALL_FONTS = [
  'mono',
  'system-ui',
  '-apple-system',
  'BlinkMacSystemFont',
  'MS Shell Dlg',
  'MS Shell Dlg \\32',
  'aakar',
  'Abadi MT Condensed',
  'Abadi MT Condensed Light',
  'ABeeZee',
  'Abel',
  'Abhaya Libre',
  'Abril Fatface',
  'Abyssinica SIL',
  'Academy Engraved LET',
  'AcadEref',
  'Aclonica',
  'Acme',
  'Actor',
  'Adamina',
  'Adlam',
  'Adobe Arabic',
  'Adobe Caslon Pro',
  'Adobe Caslon Pro Bold',
  'Adobe Devanagari',
  'Adobe Fangsong Std R',
  'Adobe Fan Heiti Std B',
  'Adobe Garamond',
  'Adobe Garamond Pro',
  'Adobe Garamond Pro Bold',
  'Adobe Gothic Std B',
  'Adobe Hebrew',
  'Adobe Heiti Std R',
  'Adobe Kaiti Std R',
  'Adobe Ming Std L',
  'Adobe Myungjo Std M',
  'Adobe Naskh Medium',
  'Adobe Song Std L',
  'Advent Pro',
  'Agency FB',
  'Aguafina Script',
  'Aharoni',
  'Aharoni Bold',
  'Aharoni CLM',
  'AIGDT',
  'Akronim',
  'Aladin',
  'AlArabiya',
  'AlBattar',
  'Al Bayan',
  'Al Bayan Bold',
  'Al Bayan Plain',
  'Albertus Extra Bold',
  'Albertus Medium',
  'Aldhabi',
  'Aldrich',
  'Alef',
  'Alegreya',
  'Alegreya Sans',
  'Alegreya Sans SC',
  'Alegreya SC',
  'Aleo',
  'Alexandra Script',
  'Alex Brush',
  'Alfa Slab One',
  'Algerian',
  'AlHor',
  'Alice',
  'Alike',
  'Alike Angular',
  'Allan',
  'Allerta',
  'Allerta Stencil',
  'Allura',
  'AlManzomah',
  'Almarai',
  'Almendra',
  'Almendra Display',
  'Almendra SC',
  'Al Nile',
  'Al Nile Bold',
  'Al Tarikh',
  'Al Tarikh Regular',
  'AlternateGothic2 BT',
  'AlYarmook',
  'Amadeus',
  'Amarante',
  'Amaranth',
  'Amatic SC',
  'Amazone BT',
  'AmdtSymbols',
  'American Typewriter',
  'American Typewriter Bold',
  'American Typewriter Condensed',
  'American Typewriter Condensed Bold',
  'American Typewriter Condensed Light',
  'American Typewriter Light',
  'American Typewriter Semibold',
  'AmerType Md BT',
  'Amethysta',
  'AMGDT',
  'Amiko',
  'Amiri',
  'Amiri Quran',
  'Amiri Quran Colored',
  'Amita',
  'Anaheim',
  'AnastasiaScript',
  'Andada',
  'Andale Mono',
  'Andalus',
  'Andika',
  'Android Emoji',
  'Angkor',
  'Angsana New',
  'Angsana New Bold',
  'Angsana New Bold Italic',
  'Angsana New Italic',
  'AngsanaUPC',
  'AngsanaUPC Bold',
  'AngsanaUPC Bold Italic',
  'AngsanaUPC Italic',
  'Ani',
  'AnjaliOldLipi',
  'Annabelle',
  'Annie Use Your Telescope',
  'Anonymous Pro',
  'Antic',
  'Antic Didone',
  'Antic Slab',
  'Antique Olive',
  'Anton',
  'Aparajita',
  'Aparajita Bold',
  'Aparajita Bold Italic',
  'Aparajita Italic',
  'Apple Braille',
  'Apple Braille Outline 6 Dot',
  'Apple Braille Outline 8 Dot',
  'Apple Braille Pinpoint 6 Dot',
  'Apple Braille Pinpoint 8 Dot',
  'Apple Chancery',
  'Apple Color Emoji',
  'AppleGothic',
  'AppleGothic Regular',
  'Apple LiGothic',
  'Apple LiGothic Medium',
  'Apple LiSung',
  'Apple LiSung Light',
  'AppleMyungjo',
  'AppleMyungjo Regular',
  'Apple SD Gothic Neo',
  'Apple SD Gothic Neo Bold',
  'Apple SD GothicNeo ExtraBold',
  'Apple SD Gothic Neo Heavy',
  'Apple SD Gothic Neo Light',
  'Apple SD Gothic Neo Medium',
  'Apple SD Gothic Neo Regular',
  'Apple SD Gothic Neo SemiBold',
  'Apple SD Gothic Neo Thin',
  'Apple SD Gothic Neo UltraLight',
  'Apple Symbols',
  'Aqua Kana',
  'AquaKana',
  'Aqua Kana Bold',
  'Arab',
  'Arabic Transparent',
  'Arabic Typesetting',
  'Arapey',
  'Arbutus',
  'Arbutus Slab',
  'Archer',
  'Architects Daughter',
  'Archivo',
  'Archivo Black',
  'Archivo Narrow',
  'Aref Ruqaa',
  'Arial',
  'Arial Baltic',
  'Arial Bold',
  'Arial Bold Italic',
  'Arial CE',
  'Arial Cyr',
  'Arial Greek',
  'Arial Hebrew',
  'Arial Hebrew Bold',
  'Arial Hebrew Light',
  'Arial Hebrew Scholar',
  'Arial Hebrew Scholar Bold',
  'Arial Hebrew Scholar Light',
  'Arial Italic',
  'Arial MT',
  'Arial Narrow',
  'Arial Narrow Bold',
  'Arial Narrow Bold Italic',
  'Arial Narrow Italic',
  'Arial Nova',
  'Arial Nova Bold',
  'Arial Nova Bold Italic',
  'Arial Nova Cond',
  'Arial Nova Cond Bold',
  'Arial Nova Cond Bold Italic',
  'Arial Nova Cond Italic',
  'Arial Nova Cond Light',
  'Arial Nova Cond Light Italic',
  'Arial Nova Italic',
  'Arial Nova Light',
  'Arial Nova Light Italic',
  'Arial Rounded MT Bold',
  'Arial TUR',
  'Arial Unicode MS',
  'Arima Madurai',
  'Arimo',
  'Ariston',
  'Arizonia',
  'Armata',
  'Arno Pro',
  'Arno Pro Caption',
  'Arno Pro Display',
  'Arno Pro Light Display',
  'Arno Pro Smbd',
  'Arno Pro Smbd Caption',
  'Arno Pro Smbd Display',
  'Arno Pro Smbd SmText',
  'Arno Pro Smbd Subhead',
  'Arno Pro SmText',
  'Arno Pro Subhead',
  'AR PL UKai CN',
  'AR PL UKai HK',
  'AR PL UKai TW',
  'AR PL UKai TW MBE',
  'AR PL UMing CN',
  'AR PL UMing HK',
  'AR PL UMing TW',
  'AR PL UMing TW MBE',
  'Arrus BT',
  'Arsenal',
  'Artifika',
  'Arundina',
  'Arvo',
  'Arya',
  'Asana Math',
  'Asap',
  'Asap Condensed',
  'Asar',
  'Asset',
  'Assistant',
  'Astloch',
  'Asul',
  'Athelas',
  'Athelas Bold',
  'Athelas Bold Italic',
  'Athelas Italic',
  'Athelas Regular',
  'Athiti',
  'Atma',
  'Atomic Age',
  'Aubrey',
  'Audiowide',
  'Aurora Cn BT',
  'Autour One',
  'AvantGarde Bk BT',
  'AvantGarde Md BT',
  'Avenir',
  'Avenir Black',
  'Avenir Black Oblique',
  'Avenir Book',
  'Avenir Book Oblique',
  'Avenir Heavy',
  'Avenir Heavy Oblique',
  'Avenir Light',
  'Avenir Light Oblique',
  'Avenir Medium',
  'Avenir Medium Oblique',
  'Avenir Next',
  'Avenir Next Bold',
  'Avenir Next Bold Italic',
  'Avenir Next Condensed',
  'Avenir Next Condensed Bold',
  'Avenir Next Condensed Bold Italic',
  'Avenir Next Condensed Demi Bold',
  'Avenir Next Condensed Demi Bold Italic',
  'Avenir Next Condensed Heavy',
  'Avenir Next Condensed Heavy Italic',
  'Avenir Next Condensed Italic',
  'Avenir Next Condensed Medium',
  'Avenir Next Condensed Medium Italic',
  'Avenir Next Condensed Regular',
  'Avenir Next Condensed Ultra Light',
  'Avenir Next Condensed Ultra Light Italic',
  'Avenir Next Demi Bold',
  'Avenir Next Demi Bold Italic',
  'Avenir Next Heavy',
  'Avenir Next Heavy Italic',
  'Avenir Next Italic',
  'Avenir Next Medium',
  'Avenir Next Medium Italic',
  'Avenir Next Regular',
  'Avenir Next Ultra Light',
  'Avenir Next Ultra Light Italic',
  'Avenir Oblique',
  'Avenir Roman',
  'Average',
  'Average Sans',
  'Averia Gruesa Libre',
  'Averia Libre',
  'Averia Sans Libre',
  'Averia Serif Libre',
  'Ayuthaya',
  'B612',
  'B612 Mono',
  'Bad Script',
  'Baghdad',
  'Baghdad Regular',
  'Bahiana',
  'Bahianita',
  'Bahnschrift',
  'Bahnschrift Light',
  'Bahnschrift SemiBold',
  'Bahnschrift SemiLight',
  'Bai Jamjuree',
  'Balinese',
  'Baloo',
  'Baloo Bhai',
  'Baloo Bhaijaan',
  'Baloo Bhaina',
  'Baloo Chettan',
  'Baloo Da',
  'Baloo Paaji',
  'Baloo Tamma',
  'Baloo Tammudu',
  'Baloo Thambi',
  'Balthazar',
  'Bamum',
  'Bandy',
  'Bangers',
  'Bangla MN',
  'Bangla MN Bold',
  'Bangla Sangam MN',
  'Bangla Sangam MN Bold',
  'Bank Gothic',
  'BankGothic Lt BT',
  'BankGothic Md BT',
  'Baoli SC',
  'Baoli SC Regular',
  'Baoli TC',
  'Baoli TC Regular',
  'Barlow',
  'Barlow Condensed',
  'Barlow Semi Condensed',
  'Barriecito',
  'Barrio',
  'Basic',
  'Baskerville',
  'Baskerville Bold',
  'Baskerville Bold Italic',
  'Baskerville Italic',
  'Baskerville Old Face',
  'Baskerville SemiBold',
  'Baskerville SemiBold Italic',
  'Bassa Vah',
  'Batak',
  'Batang',
  'BatangChe',
  'Battambang',
  'Bauer Bodoni',
  'Bauhaus 93',
  'Baumans',
  'Bayon',
  'Bazooka',
  'Bebas Neue',
  'Beirut',
  'Beirut Regular',
  'Belgrano',
  'Bellefair',
  'Belleza',
  'Bell Gothic Std Black',
  'Bell Gothic Std Light',
  'Bell MT',
  'Bembo',
  'BenchNine',
  'Bengali',
  'Benguiat Bk BT',
  'Bentham',
  'Berkshire Swash',
  'Berlin Sans FB',
  'Berlin Sans FB Demi',
  'Bernard MT Condensed',
  'BernhardFashion BT',
  'BernhardMod BT',
  'Beth Ellen',
  'Bevan',
  'Be Vietnam',
  'BiauKai',
  'Bickham Script One',
  'Bickham Script Pro Regular',
  'Bickham Script Pro Semibold',
  'Bickham Script Two',
  'Big Caslon',
  'Big Caslon Medium',
  'Bigelow Rules',
  'Bigshot One',
  'Big Shoulders Display',
  'Big Shoulders Text',
  'Bilbo',
  'Bilbo Swash Caps',
  'BinnerD',
  'BioRhyme',
  'BioRhyme Expanded',
  'Birch Std',
  'Biryani',
  'Bitstream Charter',
  'Bitstream Vera Sans Mono',
  'Bitter',
  'BIZ UDGothic',
  'BIZ UDGothic Bold',
  'BIZ UDMincho',
  'BIZ UDMincho Medium',
  'BIZ UDPGothic',
  'BIZ UDPGothic Bold',
  'BIZ UDPMincho',
  'BIZ UDPMincho Medium',
  'BIZ UDPゴシック',
  'Blackadder ITC',
  'Black And White Picture',
  'Black Han Sans',
  'Blackoak Std',
  'Black Ops One',
  'BlairMdITC TT',
  'Blinker',
  'Bodoni 72',
  'Bodoni 72 Bold',
  'Bodoni 72 Book',
  'Bodoni 72 Book Italic',
  'Bodoni 72 Oldstyle',
  'Bodoni 72 Oldstyle Bold',
  'Bodoni 72 Oldstyle Book',
  'Bodoni 72 Oldstyle Book Italic',
  'Bodoni 72 Smallcaps',
  'Bodoni 72 Smallcaps Book',
  'Bodoni MT',
  'Bodoni MT Black',
  'Bodoni MT Condensed',
  'Bodoni MT Poster Compressed',
  'Bodoni Ornaments',
  'Bokor',
  'Bonbon',
  'Boogaloo',
  'Book Antiqua',
  'Bookman Old Style',
  'Bookshelf Symbol 7',
  'Boulder',
  'Bowlby One',
  'Bowlby One SC',
  'BPG Algeti GPL&GNU',
  'BPG Chveulebrivi GPL&GNU',
  'BPG Courier GPL&GNU',
  'BPG Courier S GPL&GNU',
  'BPG DedaEna Block GPL&GNU',
  'BPG DejaVu Sans 2011 GNU-GPL',
  'BPG Elite GPL&GNU',
  'BPG Excelsior Caps GPL&GNU',
  'BPG Excelsior Condencerd GPL&GNU',
  'BPG Excelsior GPL&GNU',
  'BPG Glaho GPL&GNU',
  'BPG Gorda GPL&GNU',
  'BPG Ingiri GPL&GNU',
  'BPG Mrgvlovani Caps GNU&GPL',
  'BPG Mrgvlovani GPL&GNU',
  'BPG Nateli Caps GPL&GNU',
  'BPG Nateli Condenced GPL&GNU',
  'BPG Nateli GPL&GNU',
  'BPG Nino Medium Cond GPL&GNU',
  'BPG Nino Medium GPL&GNU',
  'BPG Sans GPL&GNU',
  'BPG Sans Medium GPL&GNU',
  'BPG Sans Modern GPL&GNU',
  'BPG Sans Regular GPL&GNU',
  'BPG Serif GPL&GNU',
  'BPG Serif Modern GPL&GNU',
  'Bradley Hand',
  'Bradley Hand Bold',
  'Bradley Hand ITC',
  'Brawler',
  'Bree Serif',
  'Bremen Bd BT',
  'Britannic Bold',
  'Broadway',
  'Browallia New',
  'Browallia New Bold',
  'Browallia New Bold Italic',
  'Browallia New Italic',
  'BrowalliaUPC',
  'BrowalliaUPC Bold',
  'BrowalliaUPC Bold Italic',
  'BrowalliaUPC Italic',
  'Brush Script MT',
  'Brush Script MT Italic',
  'Brush Script Std',
  'Bubblegum Sans',
  'Bubbler One',
  'Buda',
  'Buenard',
  'Buginese',
  'Buhid',
  'Bungee',
  'Bungee Hairline',
  'Bungee Inline',
  'Bungee Outline',
  'Bungee Shade',
  'Butcherman',
  'Butterfly Kids',
  'C059',
  'Cabin',
  'Cabin Condensed',
  'Cabin Sketch',
  'Caesar Dressing',
  'Cagliostro',
  'Cairo',
  'Caladea',
  'Caladings CLM',
  'Calibri',
  'Calibri Bold',
  'Calibri Bold Italic',
  'Calibri Italic',
  'Californian FB',
  'Calisto MT',
  'Calligraffitti',
  'Calligraph',
  'Calligrapher',
  'Cambay',
  'Cambo',
  'Cambria',
  'Cambria Bold',
  'Cambria Bold Italic',
  'Cambria Italic',
  'Cambria Math',
  'Canadian Aboriginal',
  'Candal',
  'Candara',
  'Candara Bold',
  'Candara Bold Italic',
  'Candara Italic',
  'Cantarell',
  'Cantarell Extra Bold',
  'Cantarell Light',
  'Cantarell Thin',
  'Cantata One',
  'Cantora One',
  'Capriola',
  'Cardo',
  'Carlito',
  'Carme',
  'Carolina',
  'Carrois Gothic',
  'Carrois Gothic SC',
  'Carter One',
  'Cascadia Code',
  'Cascadia Mono',
  'CaslonOpnface BT',
  'Castellar',
  'Catamaran',
  'Caudex',
  'Caveat',
  'Caveat Brush',
  'Cedarville Cursive',
  'Centaur',
  'Century',
  'Century Gothic',
  'Century Schoolbook',
  'Century Schoolbook L',
  'Ceremonious Two',
  'Ceviche One',
  'Cezanne',
  'CG Omega',
  'CG Times',
  'Chakma',
  'Chakra Petch',
  'Chalkboard',
  'Chalkboard Bold',
  'Chalkboard SE',
  'Chalkboard SE Bold',
  'Chalkboard SE Light',
  'Chalkboard SE Regular',
  'Chalkduster',
  'Cham',
  'Chandas',
  'Changa',
  'Changa One',
  'Chango',
  'Chaparral Pro',
  'Chaparral Pro Light',
  'Charcoal CY',
  'Charis SIL Compact',
  'Charlemagne Std',
  'Charlesworth',
  'Charm',
  'Charmonman',
  'Charter',
  'Charter Bd BT',
  'Charter Black',
  'Charter Black Italic',
  'Charter Bold',
  'Charter Bold Italic',
  'Charter BT',
  'Charter Italic',
  'Charter Roman',
  'Chathura',
  'Chaucer',
  'Chau Philomene One',
  'Chela One',
  'Chelsea Market',
  'ChelthmITC Bk BT',
  'Chenla',
  'Cherokee',
  'Cherry Cream Soda',
  'Cherry Swash',
  'Chewy',
  'Chicle',
  'Chilanka',
  'Chiller',
  'Chivo',
  'Chonburi',
  'Cinzel',
  'Cinzel Decorative',
  'CityBlueprint',
  'Clarendon',
  'Clarendon Blk BT',
  'Clarendon BT',
  'Clarendon Condensed',
  'Clarendon Lt BT',
  'Clean',
  'Clicker Script',
  'CloisterBlack BT',
  'cmex10',
  'cmmi10',
  'cmr10',
  'cmsy10',
  'Cochin',
  'Cochin Bold',
  'Cochin Bold Italic',
  'Cochin Italic',
  'Coda',
  'Coda Caption',
  'Codystar',
  'Coiny',
  'Colonna MT',
  'Combo',
  'Comfortaa',
  'Comfortaa Light',
  'Comic Sans',
  'Comic Sans MS',
  'Comic Sans MS Bold',
  'Comic Sans MS Bold Italic',
  'Comic Sans MS Italic',
  'Coming Soon',
  'CommercialPi BT',
  'CommercialScript BT',
  'Complex',
  'Concert One',
  'Condiment',
  'Consolas',
  'Consolas Bold',
  'Consolas Bold Italic',
  'Consolas Italic',
  'Constantia',
  'Constantia Bold',
  'Constantia Bold Italic',
  'Constantia Italic',
  'Content',
  'Contrail One',
  'Convergence',
  'Cookie',
  'Cooper Black',
  'Cooper Std Black',
  'Copperplate',
  'Copperplate Bold',
  'Copperplate Gothic',
  'Copperplate Gothic Bold',
  'Copperplate Gothic Light',
  'Copperplate Light',
  'CopperplGoth Bd BT',
  'Copse',
  'Coptic',
  'Copyist',
  'Corbel',
  'Corbel Bold',
  'Corbel Bold Italic',
  'Corbel Italic',
  'Corbel Light',
  'Corbel Light Italic',
  'Corben',
  'Cordia New',
  'Cordia New Bold',
  'Cordia New Bold Italic',
  'Cordia New Italic',
  'CordiaUPC',
  'CordiaUPC Bold',
  'CordiaUPC Bold Italic',
  'CordiaUPC Italic',
  'Cormorant',
  'Cormorant Garamond',
  'Cormorant Infant',
  'Cormorant SC',
  'Cormorant Unicase',
  'Cormorant Upright',
  'Cornerstone',
  'Coronet',
  'Corsiva Hebrew',
  'Corsiva Hebrew Bold',
  'Cortoba',
  'CountryBlueprint',
  'Courgette',
  'Courier',
  'Courier 10 Pitch',
  'Courier Bold',
  'Courier Bold Oblique',
  'Courier New',
  'Courier New Baltic',
  'Courier New Bold',
  'Courier New Bold Italic',
  'Courier New CE',
  'Courier New Cyr',
  'Courier New Greek',
  'Courier New Italic',
  'Courier New TUR',
  'Courier Oblique',
  'Cousine',
  'Coustard',
  'Covered By Your Grace',
  'Crafty Girls',
  'Creepster',
  'Crete Round',
  'Crimson Pro',
  'Crimson Text',
  'Croissant One',
  'Crushed',
  'Cuckoo',
  'Cuprum',
  'Curlz MT',
  'Cursor',
  'Cute Font',
  'Cutive',
  'Cutive Mono',
  'D050000L',
  'Damascus',
  'Damascus Bold',
  'Damascus Light',
  'Damascus Medium',
  'Damascus Regular',
  'Damascus Semi Bold',
  'Damion',
  'Dancing Script',
  'Dangrek',
  'Darker Grotesque',
  'DaunPenh',
  'Dauphin',
  'David',
  'David Bold',
  'David CLM',
  'David Libre',
  'Dawning of a New Day',
  'Days One',
  'DB LCD Temp',
  'Decor',
  'DecoType Naskh',
  'DecoType Naskh Regular',
  'DejaVu Math TeX Gyre',
  'DejaVu Sans',
  'DejaVu Sans Condensed',
  'DejaVu Sans Light',
  'DejaVu Sans Mono',
  'DejaVu Serif',
  'DejaVu Serif Condensed',
  'Dekko',
  'Delicious',
  'Delius',
  'Delius Swash Caps',
  'Delius Unicase',
  'Della Respira',
  'DengXian',
  'DengXian Bold',
  'DengXian Light',
  'Denk One',
  'Denmark',
  'Deseret',
  'Devanagari',
  'Devanagari MT',
  'Devanagari MT Bold',
  'Devanagari Sangam MN',
  'Devanagari Sangam MN Bold',
  'Devonshire',
  'DFKai-SB',
  'Dhurjati',
  'Didact Gothic',
  'Didot',
  'Didot Bold',
  'Didot Italic',
  'DilleniaUPC',
  'DilleniaUPC Bold',
  'DilleniaUPC Bold Italic',
  'DilleniaUPC Italic',
  'Dimnah',
  'DIN',
  'DIN Alternate',
  'DIN Alternate Bold',
  'DIN Condensed',
  'DIN Condensed Bold',
  'Dingbats',
  'Diplomata',
  'Diplomata SC',
  'Diwan Kufi',
  'Diwan Kufi Regular',
  'Diwan Mishafi',
  'Diwan Thuluth',
  'Diwan Thuluth Regular',
  'DM Sans',
  'DM Serif Display',
  'DM Serif Text',
  'Dogra',
  'Do Hyeon',
  'DokChampa',
  'Dokdo',
  'Domine',
  'Donegal One',
  'Doppio One',
  'Dorsa',
  'Dosis',
  'Dotum',
  'DotumChe',
  'Droid Arabic Kufi',
  'Droid Arabic Naskh',
  'Droid Naskh Shift Alt',
  'Droid Sans',
  'Droid Sans Arabic',
  'Droid Sans Armenian',
  'Droid Sans Devanagari',
  'Droid Sans Ethiopic',
  'Droid Sans Fallback',
  'Droid Sans Georgian',
  'Droid Sans Hebrew',
  'Droid Sans Japanese',
  'Droid Sans Mono',
  'Droid Sans Tamil',
  'Droid Sans Thai',
  'Droid Serif',
  'Dr Sugiyama',
  'Drugulin CLM',
  'Duru Sans',
  'Dutch801 Rm BT',
  'Dutch801 XBd BT',
  'Dynalight',
  'Dyuthi',
  'Eagle Lake',
  'East Sea Dokdo',
  'Eater',
  'EB Garamond',
  'Ebrima',
  'Ebrima Bold',
  'Eccentric Std',
  'Economica',
  'Eczar',
  'Edwardian Script ITC',
  'Eeyek Unicode',
  'Elbasan',
  'Electrolize',
  'Electron',
  'Elephant',
  'Ellinia CLM',
  'El Messiri',
  'Elsie',
  'Elsie Swash Caps',
  'Emblema One',
  'Emilys Candy',
  'EmojiOne Mozilla',
  'Encode Sans',
  'Encode Sans Condensed',
  'Encode Sans Expanded',
  'Encode Sans Semi Condensed',
  'Encode Sans Semi Expanded',
  'Engagement',
  'Englebert',
  'English 111 Vivace BT',
  'EngraversGothic BT',
  'Engravers MT',
  'Enriqueta',
  'Eras Bold ITC',
  'Eras Demi ITC',
  'Eras Light ITC',
  'Eras Medium ITC',
  'Erica One',
  'esint10',
  'Esteban',
  'Estrangelo Edessa',
  'Ethiopic',
  'EucrosiaUPC',
  'EucrosiaUPC Bold',
  'EucrosiaUPC Bold Italic',
  'EucrosiaUPC Italic',
  'eufm10',
  'Euphemia',
  'Euphemia UCAS',
  'Euphemia UCAS Bold',
  'Euphemia UCAS Italic',
  'Euphoria Script',
  'EuroRoman',
  'Eurostile',
  'Ewert',
  'Exo',
  'Exo 2',
  'Exotc350 Bd BT',
  'Expletus Sans',
  'Ezra SIL',
  'Ezra SIL SR',
  'Fahkwang',
  'FangSong',
  'Fanwood Text',
  'Farah',
  'Farah Regular',
  'Farisi',
  'Farisi Regular',
  'Farro',
  'Farsan',
  'Fascinate',
  'Fascinate Inline',
  'Faster One',
  'Fasthand',
  'Fauna One',
  'Faustina',
  'Federant',
  'Federo',
  'Felipa',
  'Felix Titling',
  'Fenix',
  'Finger Paint',
  'Fira Code',
  'Fira Mono',
  'Fira Sans',
  'Fira Sans Condensed',
  'Fira Sans Extra Condensed',
  'Fixed',
  'Fixed Miriam Transparent',
  'Fixedsys',
  'Fjalla One',
  'Fjord One',
  'Flamenco',
  'Flavors',
  'Fondamento',
  'FontAwesome',
  'Fontdiner Swanky',
  'Fontin',
  'Footlight MT Light',
  'Forte',
  'Forum',
  'Francois One',
  'Franklin Gothic Book',
  'Franklin Gothic Demi',
  'Franklin Gothic Demi Cond',
  'Franklin Gothic Heavy',
  'Franklin Gothic Medium',
  'Franklin Gothic Medium Cond',
  'Franklin Gothic Medium Italic',
  'FrankRuehl',
  'Frank Ruehl CLM',
  'Frank Ruhl Libre',
  'Fransiscan',
  'Freckle Face',
  'Fredericka the Great',
  'Fredoka One',
  'Free Avant Garde',
  'Free Bookman',
  'Free Chancery',
  'Free Courier',
  'Freefrm721 Blk BT',
  'Freehand',
  'Freehand521 BT',
  'Free Helvetian',
  'Free Helvetian Condensed',
  'FreeMono',
  'Free Paladin',
  'FreeSans',
  'Free Schoolbook',
  'FreeSerif',
  'FreesiaUPC',
  'FreesiaUPC Bold',
  'FreesiaUPC Bold Italic',
  'FreesiaUPC Italic',
  'Freestyle Script',
  'Free Times',
  'French Script MT',
  'Fresca',
  'Frijole',
  'FrnkGothITC Bk BT',
  'Fruitger',
  'Fruktur',
  'Frutiger',
  'Fugaz One',
  'Furat',
  'Futura',
  'Futura Bk BT',
  'FuturaBlack BT',
  'Futura Bold',
  'Futura Condensed ExtraBold',
  'Futura Condensed Medium',
  'Futura Lt BT',
  'Futura Md BT',
  'Futura Medium',
  'Futura Medium Italic',
  'Futura ZBlk BT',
  'Gabriela',
  'Gabriola',
  'Gadugi',
  'Gadugi Bold',
  'Gaegu',
  'Gafata',
  'Galada',
  'Galdeano',
  'Galindo',
  'Galliard BT',
  'Galvji',
  'Gamja Flower',
  'Garamond',
  'Garamond Premr Pro',
  'Garamond Premr Pro Smbd',
  'Gargi',
  'Garuda',
  'Gautami',
  'Gautami Bold',
  'Gayathri',
  'Gayathri Thin',
  'GB18030 Bitmap',
  'GDT',
  'Geeza Pro',
  'Geeza Pro Bold',
  'Geeza Pro Regular',
  'Geneva',
  'Geneva CY',
  'GENISO',
  'Gentium',
  'GentiumAlt',
  'Gentium Basic',
  'Gentium Book Basic',
  'Geo',
  'Geometr231 BT',
  'Geometr231 Hv BT',
  'Geometr231 Lt BT',
  'Georgia',
  'Georgia Bold',
  'Georgia Bold Italic',
  'Georgia Italic',
  'Georgian',
  'Georgia Pro',
  'Georgia Pro Black',
  'Georgia Pro Black Italic',
  'Georgia Pro Bold',
  'Georgia Pro Bold Italic',
  'Georgia Pro Cond',
  'Georgia Pro Cond Black',
  'Georgia Pro Cond Black Italic',
  'Georgia Pro Cond Bold',
  'Georgia Pro Cond Bold Italic',
  'Georgia Pro Cond Italic',
  'Georgia Pro Cond Light',
  'Georgia Pro Cond Light Italic',
  'Georgia Pro Cond Semibold',
  'Georgia Pro Cond Semibold Italic',
  'Georgia Pro Italic',
  'Georgia Pro Light',
  'Georgia Pro Light Italic',
  'Georgia Pro Semibold',
  'Georgia Pro Semibold Italic',
  'GeoSlab 703 Lt BT',
  'GeoSlab 703 XBd BT',
  'Geostar',
  'Geostar Fill',
  'Germania One',
  'GFS Didot',
  'GFS Neohellenic',
  'Giddyup Std',
  'Gidugu',
  'Gigi',
  'Gilda Display',
  'Gill Sans',
  'Gill Sans Bold',
  'Gill Sans Bold Italic',
  'Gill Sans Italic',
  'Gill Sans Light',
  'Gill Sans Light Italic',
  'Gill Sans MT',
  'Gill Sans MT Condensed',
  'Gill Sans MT Ext Condensed Bold',
  'Gill Sans Nova',
  'Gill Sans Nova Bold',
  'Gill Sans Nova Bold Italic',
  'Gill Sans Nova Cond',
  'Gill Sans Nova Cond Bold',
  'Gill Sans Nova Cond Bold Italic',
  'Gill Sans Nova Cond Italic',
  'Gill Sans Nova Cond Lt',
  'Gill Sans Nova Cond Lt Italic',
  'Gill Sans Nova Cond Ultra Bold',
  'Gill Sans Nova Cond XBd',
  'Gill Sans Nova Cond XBd Italic',
  'Gill Sans Nova Italic',
  'Gill Sans Nova Light',
  'Gill Sans Nova Light Italic',
  'Gill Sans Nova Ultra Bold',
  'Gill Sans SemiBold',
  'Gill Sans SemiBold Italic',
  'Gill Sans Ultra Bold',
  'Gill Sans UltraBold',
  'Gill Sans Ultra Bold Condensed',
  'Gisha',
  'Gisha Bold',
  'Give You Glory',
  'Glass Antiqua',
  'Glegoo',
  'Gloria Hallelujah',
  'Gloucester MT Extra Condensed',
  'Goblin One',
  'Gochi Hand',
  'Google Sans',
  'Gorditas',
  'Gotham',
  'Gotham Bold',
  'Gothic A1',
  'GothicE',
  'GothicG',
  'GothicI',
  'Goudy Bookletter 1911',
  'GoudyHandtooled BT',
  'Goudy Old Style',
  'GoudyOLSt BT',
  'Goudy Stout',
  'Graduate',
  'Granada',
  'Grand Hotel',
  'Grantha',
  'Graph',
  'Gravitas One',
  'Great Vibes',
  'GreekC',
  'GreekS',
  'Grenze',
  'Griffy',
  'Gruppo',
  'Gubbi',
  'Gudea',
  'Gugi',
  'Gujarati',
  'Gujarati MT',
  'Gujarati MT Bold',
  'Gujarati Sangam MN',
  'Gujarati Sangam MN Bold',
  'Gulim',
  'GulimChe',
  'GungSeo',
  'GungSeo Regular',
  'Gungsuh',
  'GungsuhChe',
  'Gunjala Gondi',
  'Gurajada',
  'Gurmukhi',
  'Gurmukhi MN',
  'Gurmukhi MN Bold',
  'Gurmukhi MT',
  'Gurmukhi Sangam MN',
  'Gurmukhi Sangam MN Bold',
  'Habibi',
  'Hadasim CLM',
  'Haettenschweiler',
  'Halant',
  'Hammersmith One',
  'Hanalei',
  'Hanalei Fill',
  'Handlee',
  'Hani',
  'Hanifi Rohingya',
  'Hannotate SC',
  'Hannotate SC Bold',
  'Hannotate SC Regular',
  'Hannotate TC',
  'Hannotate TC Bold',
  'Hannotate TC Regular',
  'Hanuman',
  'Hanunoo',
  'HanziPen SC',
  'HanziPen SC Bold',
  'HanziPen SC Regular',
  'HanziPen TC',
  'HanziPen TC Bold',
  'HanziPen TC Regular',
  'Happy Monkey',
  'Haramain',
  'Harlow Solid Italic',
  'Harmattan',
  'Harrington',
  'Headland One',
  'HeadLineA',
  'HeadLineA Regular',
  'Heather',
  'Heather Script One',
  'Heebo',
  'Hei',
  'Hei Regular',
  'Heiti SC',
  'Heiti SC Light',
  'Heiti SC Medium',
  'Heiti TC',
  'Heiti TC Light',
  'Heiti TC Medium',
  'Helv',
  'Helvetica',
  'Helvetica Bold',
  'Helvetica Bold Oblique',
  'Helvetica CY',
  'Helvetica CY Bold',
  'Helvetica CY BoldOblique',
  'Helvetica CY Oblique',
  'Helvetica CY Plain',
  'Helvetica Light',
  'Helvetica Light Oblique',
  'Helvetica LT MM',
  'Helvetica Neue',
  'Helvetica Neue Bold',
  'Helvetica Neue Bold Italic',
  'Helvetica Neue Condensed Black',
  'Helvetica Neue Condensed Bold',
  'Helvetica Neue DeskInterface',
  'Helvetica Neue Desk UI',
  'Helvetica Neue Desk UI Bold',
  'Helvetica Neue Desk UI Bold Italic',
  'Helvetica Neue Desk UI Italic',
  'Helvetica Neue Italic',
  'Helvetica Neue Light',
  'Helvetica Neue Light Italic',
  'Helvetica Neue Medium',
  'Helvetica Neue Medium Italic',
  'Helvetica Neue Thin',
  'Helvetica Neue Thin Italic',
  'Helvetica Neue UltraLight',
  'Helvetica Neue UltraLight Italic',
  'Helvetica Oblique',
  'Henny Penny',
  'Hepta Slab',
  'Herald',
  'Herculanum',
  'Herr Von Muellerhoff',
  'Hershey-Gothic-English',
  'Hershey-Gothic-German',
  'Hershey-Gothic-Italian',
  'Hershey-Plain-Duplex',
  'Hershey-Plain-Duplex-Italic',
  'Hershey-Plain-Triplex',
  'Hershey-Plain-Triplex-Italic',
  'Hershey-Script-Complex',
  'Hershey-Script-Simplex',
  'High Tower Text',
  'Hi Melody',
  'Hind',
  'Hind Guntur',
  'Hind Madurai',
  'Hind Siliguri',
  'Hind Vadodara',
  'Hiragino Kaku Gothic Pro',
  'Hiragino Kaku Gothic ProN',
  'Hiragino Kaku Gothic ProN W3',
  'Hiragino Kaku Gothic ProN W6',
  'Hiragino Kaku Gothic Pro W3',
  'Hiragino Kaku Gothic Pro W6',
  'Hiragino Kaku Gothic Std',
  'Hiragino Kaku Gothic StdN',
  'Hiragino Kaku Gothic StdN W8',
  'Hiragino Kaku Gothic Std W8',
  'Hiragino Maru Gothic Pro',
  'Hiragino Maru Gothic ProN',
  'Hiragino Maru Gothic ProN W4',
  'Hiragino Maru Gothic Pro W4',
  'Hiragino Mincho Pro',
  'Hiragino Mincho ProN',
  'Hiragino Mincho ProN W3',
  'Hiragino Mincho ProN W6',
  'Hiragino Mincho Pro W3',
  'Hiragino Mincho Pro W6',
  'Hiragino Sans',
  'Hiragino Sans CNS',
  'Hiragino Sans CNS W3',
  'Hiragino Sans CNS W6',
  'Hiragino Sans GB',
  'Hiragino Sans GB W3',
  'Hiragino Sans GB W6',
  'Hiragino Sans W0',
  'Hiragino Sans W1',
  'Hiragino Sans W2',
  'Hiragino Sans W3',
  'Hiragino Sans W4',
  'Hiragino Sans W5',
  'Hiragino Sans W6',
  'Hiragino Sans W7',
  'Hiragino Sans W8',
  'Hiragino Sans W9',
  'Hobo Std',
  'Hoefler Text',
  'Hoefler Text Black',
  'Hoefler Text Black Italic',
  'Hoefler Text Italic',
  'Hoefler Text Ornaments',
  'HoloLens MDL2 Assets',
  'Holtwood One SC',
  'Homa',
  'Homemade Apple',
  'Homenaje',
  'Hor',
  'Humanst521 BT',
  'Humanst 521 Cn BT',
  'Humanst521 Lt BT',
  'IBM Plex Mono',
  'IBM Plex Sans',
  'IBM Plex Sans Condensed',
  'IBM Plex Serif',
  'Iceberg',
  'Iceland',
  'IM Fell Double Pica',
  'IM Fell Double Pica SC',
  'IM Fell DW Pica',
  'IM Fell DW Pica SC',
  'IM Fell English',
  'IM Fell English SC',
  'IM Fell French Canon',
  'IM Fell French Canon SC',
  'IM Fell Great Primer',
  'IM Fell Great Primer SC',
  'Impact',
  'Imprima',
  'Imprint MT Shadow',
  'InaiMathi',
  'InaiMathi Bold',
  'Incised901 Bd BT',
  'Incised901 BT',
  'Incised901 Lt BT',
  'Inconsolata',
  'Inder',
  'Indie Flower',
  'Informal011 BT',
  'Informal Roman',
  'Inika',
  'Ink Free',
  'Inknut Antiqua',
  'Interstate',
  'Iowan Old Style',
  'Iowan Old Style Black',
  'Iowan Old Style Black Italic',
  'Iowan Old Style Bold',
  'Iowan Old Style Bold Italic',
  'Iowan Old Style Italic',
  'Iowan Old Style Roman',
  'Iowan Old Style Titling',
  'IPAGothic',
  'IPAMincho',
  'IPAPGothic',
  'IPAPMincho',
  'Irish Grover',
  'IrisUPC',
  'IrisUPC Bold',
  'IrisUPC Bold Italic',
  'IrisUPC Italic',
  'Iskoola Pota',
  'Iskoola Pota Bold',
  'ISOCP',
  'ISOCP2',
  'ISOCP3',
  'ISOCPEUR',
  'ISOCT',
  'ISOCT2',
  'ISOCT3',
  'ISOCTEUR',
  'Istok Web',
  'Italiana',
  'Italianno',
  'Italic',
  'ItalicC',
  'ItalicT',
  'ITF Devanagari',
  'ITF Devanagari Bold',
  'ITF Devanagari Book',
  'ITF Devanagari Demi',
  'ITF Devanagari Light',
  'ITF Devanagari Marathi',
  'ITF Devanagari Marathi Bold',
  'ITF Devanagari Marathi Book',
  'ITF Devanagari Marathi Demi',
  'ITF Devanagari Marathi Light',
  'ITF Devanagari Marathi Medium',
  'ITF Devanagari Medium',
  'Itim',
  'Jacques Francois',
  'Jacques Francois Shadow',
  'Jaldi',
  'Jamrul',
  'Japan',
  'JasmineUPC',
  'JasmineUPC Bold',
  'JasmineUPC Bold Italic',
  'JasmineUPC Italic',
  'Javanese',
  'Javanese Text',
  'Jazz LET',
  'Jenson',
  'Jester',
  'Jet',
  'Jim Nightshade',
  'Jockey One',
  'Jokerman',
  'Jolly Lodger',
  'Jomhuria',
  'Jomolhari',
  'Josefin Sans',
  'Josefin Slab',
  'Joti One',
  'Jua',
  'Judson',
  'Juice ITC',
  'Julee',
  'Julius Sans One',
  'Junge',
  'Jura',
  'Just Another Hand',
  'Just Me Again Down Here',
  'K2D',
  'Kabel Bk BT',
  'Kabel Ult BT',
  'KacstArt',
  'KacstBook',
  'KacstDecorative',
  'KacstDigital',
  'KacstFarsi',
  'KacstLetter',
  'KacstNaskh',
  'KacstOffice',
  'KacstOne',
  'KacstPen',
  'KacstPoster',
  'KacstQurn',
  'KacstScreen',
  'KacstTitle',
  'KacstTitleL',
  'Kadwa',
  'Kai',
  'Kailasa',
  'Kailasa Bold',
  'Kailasa Regular',
  'Kai Regular',
  'KaiTi',
  'Kaiti SC',
  'Kaiti SC Black',
  'Kaiti SC Bold',
  'Kaiti SC Regular',
  'Kaiti TC',
  'Kaiti TC Black',
  'Kaiti TC Bold',
  'Kaiti TC Regular',
  'Kalam',
  'Kalapi',
  'Kalimati',
  'Kalinga',
  'Kalinga Bold',
  'Kalyani',
  'Kameron',
  'Kanit',
  'Kannada',
  'Kannada MN',
  'Kannada MN Bold',
  'Kannada Sangam MN',
  'Kannada Sangam MN Bold',
  'Kantumruy',
  'Karla',
  'Karma',
  'Kartika',
  'Kartika Bold',
  'Karumbi',
  'Katibeh',
  'Kaufmann Bd BT',
  'Kaufmann BT',
  'Kaushan Script',
  'Kavivanar',
  'Kavoon',
  'Kayah Li',
  'Kayrawan',
  'Kdam Thmor',
  'Keania One',
  'Kedage',
  'Kefa',
  'Kefa Bold',
  'Kefa Regular',
  'Kelly Slab',
  'Kenia',
  'Keraleeyam',
  'Keter YG',
  'Keyboard',
  'Khalid',
  'Khand',
  'Khmer',
  'Khmer MN',
  'Khmer MN Bold',
  'Khmer OS',
  'Khmer OS Battambang',
  'Khmer OS Bokor',
  'Khmer OS Content',
  'Khmer OS Fasthand',
  'Khmer OS Freehand',
  'Khmer OS Metal Chrieng',
  'Khmer OS Muol',
  'Khmer OS Muol Light',
  'Khmer OS Muol Pali',
  'Khmer OS Siemreap',
  'Khmer OS System',
  'Khmer Sangam MN',
  'Khmer UI',
  'Khmer UI Bold',
  'Khojki',
  'Khudawadi',
  'Khula',
  'Kinnari',
  'Kirang Haerang',
  'Kite One',
  'Klee',
  'Klee Demibold',
  'Klee Medium',
  'Knewave',
  'Kodchasan',
  'KodchiangUPC',
  'KodchiangUPC Bold',
  'KodchiangUPC Bold Italic',
  'KodchiangUPC Italic',
  'Kohinoor Bangla',
  'Kohinoor Bangla Bold',
  'Kohinoor Bangla Light',
  'Kohinoor Bangla Medium',
  'Kohinoor Bangla Semibold',
  'Kohinoor Devanagari',
  'Kohinoor Devanagari Bold',
  'Kohinoor Devanagari Light',
  'Kohinoor Devanagari Medium',
  'Kohinoor Devanagari Regular',
  'Kohinoor Devanagari Semibold',
  'Kohinoor Gujarati',
  'Kohinoor Gujarati Light',
  'Kohinoor Gujarati Medium',
  'Kohinoor Gujarati Semibold',
  'Kohinoor Telugu',
  'Kohinoor Telugu Bold',
  'Kohinoor Telugu Light',
  'Kohinoor Telugu Medium',
  'Kohinoor Telugu Semibold',
  'KoHo',
  'Kokila',
  'Kokila Bold',
  'Kokila Bold Italic',
  'Kokila Italic',
  'Kokonor',
  'Kokonor Regular',
  'Korinna BT',
  'Kosugi',
  'Kosugi Maru',
  'Kotta One',
  'Koulen',
  'Kozuka Gothic Pr6N B',
  'Kozuka Gothic Pr6N EL',
  'Kozuka Gothic Pr6N H',
  'Kozuka Gothic Pr6N L',
  'Kozuka Gothic Pr6N M',
  'Kozuka Gothic Pr6N R',
  'Kozuka Gothic Pro B',
  'Kozuka Gothic Pro EL',
  'Kozuka Gothic Pro H',
  'Kozuka Gothic Pro L',
  'Kozuka Gothic Pro M',
  'Kozuka Gothic Pro R',
  'Kozuka Mincho Pr6N B',
  'Kozuka Mincho Pr6N EL',
  'Kozuka Mincho Pr6N H',
  'Kozuka Mincho Pr6N L',
  'Kozuka Mincho Pr6N M',
  'Kozuka Mincho Pr6N R',
  'Kozuka Mincho Pro B',
  'Kozuka Mincho Pro EL',
  'Kozuka Mincho Pro H',
  'Kozuka Mincho Pro L',
  'Kozuka Mincho Pro M',
  'Kozuka Mincho Pro R',
  'Kranky',
  'Kreon',
  'Kristen ITC',
  'Kristi',
  'Krona One',
  'Krub',
  'Krungthep',
  'KufiStandardGK',
  'KufiStandardGK Regular',
  'Kulim Park',
  'Kumar One',
  'Kumar One Outline',
  'Kunstler Script',
  'Kurale',
  'La Belle Aurore',
  'Lacquer',
  'Laila',
  'Lakki Reddy',
  'Laksaman',
  'Lalezar',
  'Lancelot',
  'Lantinghei SC',
  'Lantinghei SC Demibold',
  'Lantinghei SC Extralight',
  'Lantinghei SC Heavy',
  'Lantinghei TC',
  'Lantinghei TC Demibold',
  'Lantinghei TC Extralight',
  'Lantinghei TC Heavy',
  'Lao',
  'Lao MN',
  'Lao MN Bold',
  'Lao Sangam MN',
  'Lao UI',
  'Lao UI Bold',
  'LastResort',
  'Lateef',
  'Latha',
  'Latha Bold',
  'Latin Modern Math',
  'Lato',
  'League Script',
  'Leckerli One',
  'Ledger',
  'Leelawadee',
  'Leelawadee Bold',
  'Leelawadee UI',
  'Leelawadee UI Bold',
  'Leelawadee UI Leelawadee UI',
  'Leelawadee UI Semilight',
  'Lekton',
  'Lemon',
  'Lemonada',
  'Lepcha',
  'Letter Gothic',
  'Letter Gothic Std',
  'Levenim MT',
  'Levenim MT Bold',
  'Lexend Deca',
  'Lexend Exa',
  'Lexend Giga',
  'Lexend Mega',
  'Lexend Peta',
  'Lexend Tera',
  'Lexend Zetta',
  'Liberation Mono',
  'Liberation Sans',
  'Liberation Sans Narrow',
  'Liberation Serif',
  'Libertinus Math',
  'Libian SC',
  'Libian SC Regular',
  'Libian TC',
  'Libian TC Regular',
  'Libre Barcode 39',
  'Libre Barcode 39 Extended',
  'Libre Barcode 39 Extended Text',
  'Libre Barcode 39 Text',
  'Libre Barcode 128',
  'Libre Barcode 128 Text',
  'Libre Baskerville',
  'Libre Caslon Display',
  'Libre Caslon Text',
  'Libre Franklin',
  'Life Savers',
  'LiHei Pro',
  'Likhan',
  'Lilita One',
  'Lily Script One',
  'LilyUPC',
  'LilyUPC Bold',
  'LilyUPC Bold Italic',
  'LilyUPC Italic',
  'Limbu',
  'Limelight',
  'Linden Hill',
  'LingWai SC',
  'LingWai SC Medium',
  'LingWai TC',
  'LingWai TC Medium',
  'Linux Biolinum Keyboard O',
  'Linux Biolinum O',
  'Linux Libertine Display O',
  'Linux Libertine Initials O',
  'Linux Libertine Mono O',
  'Linux Libertine O',
  'LiSong Pro',
  'Lisu',
  'Literata',
  'Lithograph',
  'Lithograph Light',
  'Lithos Pro Regular',
  'Liu Jian Mao Cao',
  'Livvic',
  'LKLUG',
  'Lobster',
  'Lobster Two',
  'Lohit Assamese',
  'Lohit Bengali',
  'Lohit Devanagari',
  'Lohit Gujarati',
  'Lohit Gurmukhi',
  'Lohit Hindi',
  'Lohit Kannada',
  'Lohit Malayalam',
  'Lohit Marathi',
  'Lohit Nepali',
  'Lohit Odia',
  'Lohit Oriya',
  'Lohit Punjabi',
  'Lohit Tamil',
  'Lohit Tamil Classical',
  'Lohit Telugu',
  'Loma',
  'Londrina Outline',
  'Londrina Shadow',
  'Londrina Sketch',
  'Londrina Solid',
  'Long Cang',
  'Long Island',
  'Lora',
  'Loved by the King',
  'Lovers Quarrel',
  'Love Ya Like A Sister',
  'Lucida Bright',
  'Lucida Calligraphy',
  'Lucida Console',
  'Lucida Fax',
  'Lucida Grande',
  'Lucida Grande Bold',
  'Lucida Handwriting',
  'Lucida Sans',
  'Lucida Sans Typewriter',
  'Lucida Sans Unicode',
  'Luckiest Guy',
  'Luminari',
  'Lusitana',
  'Lustria',
  'Lydian BT',
  'Macondo',
  'Macondo Swash Caps',
  'Mada',
  'Madan',
  'Magneto',
  'Magra',
  'Mahajani',
  'Maiandra GD',
  'Maiden Orange',
  'Maitree',
  'Major Mono Display',
  'Mako',
  'Malayalam',
  'Malayalam MN',
  'Malayalam MN Bold',
  'Malayalam Sangam MN',
  'Malayalam Sangam MN Bold',
  'Malgun Gothic',
  'Malgun Gothic Bold',
  'Malgun Gothic Semilight',
  'Mali',
  'Mallanna',
  'Mallige',
  'Mandaic',
  'Mandali',
  'Mangal',
  'Mangal Bold',
  'Manjari',
  'Manjari Thin',
  'Mansalva',
  'Manuale',
  'Marcellus',
  'Marcellus SC',
  'Marck Script',
  'Margarine',
  'Marigold',
  'Marion',
  'Marion Bold',
  'Marion Italic',
  'Marion Regular',
  'Markazi Text',
  'Marker Felt',
  'Marker Felt Thin',
  'Marker Felt Wide',
  'Market',
  'Marko One',
  'Marlett',
  'Marmelad',
  'Martel',
  'Martel Sans',
  'Marvel',
  'Masaram Gondi',
  'Ma Shan Zheng',
  'Mashq',
  'Mashq-Bold',
  'Mate',
  'Material Icons',
  'Mate SC',
  'Matisse ITC',
  'Matura MT Script Capitals',
  'Maven Pro',
  'McLaren',
  'Meddon',
  'Medefaidrin',
  'MedievalSharp',
  'Medula One',
  'Meera',
  'Meera Inimai',
  'Meetei Mayek',
  'Megrim',
  'Meie Script',
  'Meiryo',
  'Meiryo Bold',
  'Meiryo Bold Italic',
  'Meiryo Italic',
  'Meiryo UI',
  'Meiryo UI Bold',
  'Meiryo UI Bold Italic',
  'Meiryo UI Italic',
  'Mende Kikakui',
  'Menlo',
  'Menlo Bold',
  'Menlo Bold Italic',
  'Menlo Italic',
  'Menlo Regular',
  'Merienda',
  'Merienda One',
  'Merriweather',
  'Merriweather Sans',
  'Mesquite Std',
  'Metal',
  'Metal Mania',
  'Metamorphous',
  'Metrophobic',
  'Miao',
  'Michroma',
  'Microsoft Himalaya',
  'Microsoft JhengHei',
  'Microsoft JhengHei Bold',
  'Microsoft JhengHei Light',
  'Microsoft JhengHei Regular',
  'Microsoft JhengHei UI',
  'Microsoft JhengHei UI Bold',
  'Microsoft JhengHei UI Light',
  'Microsoft JhengHei UI Regular',
  'Microsoft New Tai Lue',
  'Microsoft New Tai Lue Bold',
  'Microsoft PhagsPa',
  'Microsoft PhagsPa Bold',
  'Microsoft Sans Serif',
  'Microsoft Tai Le',
  'Microsoft Tai Le Bold',
  'Microsoft Uighur',
  'Microsoft Uighur Bold',
  'Microsoft YaHei',
  'Microsoft YaHei Bold',
  'Microsoft YaHei Light',
  'Microsoft YaHei UI',
  'Microsoft YaHei UI Bold',
  'Microsoft YaHei UI Light',
  'Microsoft Yi Baiti',
  'Milonga',
  'Miltonian',
  'Miltonian Tattoo',
  'Mina',
  'MingLiU',
  'MingLiU-ExtB',
  'MingLiU_HKSCS',
  'MingLiU_HKSCS-ExtB',
  'Mingzat',
  'Minion',
  'Minion Pro',
  'Minion Pro Cond',
  'Minion Pro Med',
  'Minion Pro SmBd',
  'Miniver',
  'Miriam',
  'Miriam CLM',
  'Miriam Fixed',
  'Miriam Libre',
  'Miriam Mono CLM',
  'Mirza',
  'Mishafi',
  'Mishafi Gold',
  'Mishafi Gold Regular',
  'Mishafi Regular',
  'Miss Fajardose',
  'Mistral',
  'Mitr',
  'Mitra Mono',
  'Modak',
  'Modern',
  'Modern Antiqua',
  'Modern No. 20',
  'Modi',
  'Mogra',
  'Molengo',
  'Molle',
  'Monaco',
  'Monaco CY',
  'Mona Lisa Solid ITC TT',
  'Monda',
  'Mongolian',
  'Mongolian Baiti',
  'Monofett',
  'Monospac821 BT',
  'Monoton',
  'Monotxt',
  'Monotype Corsiva',
  'Monsieur La Doulaise',
  'Montaga',
  'Montez',
  'Montserrat',
  'Montserrat Alternates',
  'Montserrat Black',
  'Montserrat ExtraBold',
  'Montserrat ExtraLight',
  'Montserrat Light',
  'Montserrat Medium',
  'Montserrat SemiBold',
  'Montserrat Subrayada',
  'Montserrat Thin',
  'MoolBoran',
  'Moul',
  'Moulpali',
  'Mountains of Christmas',
  'Mouse Memoirs',
  'M PLUS 1e',
  'M PLUS 1p',
  'M PLUS Rounded 1c',
  'M PLUS Rounded 2c',
  'Mr Bedfort',
  'Mr Dafoe',
  'Mr De Haviland',
  'Mro',
  'Mrs Eaves',
  'Mrs Saint Delafield',
  'Mrs Sheppards',
  'mry_KacstQurn',
  'msam10',
  'msbm10',
  'MS Gothic',
  'Mshtakan',
  'Mshtakan Bold',
  'Mshtakan BoldOblique',
  'Mshtakan Oblique',
  'MS LineDraw',
  'MS Mincho',
  'MS Outlook',
  'MS PGothic',
  'MS PMincho',
  'MS Reference Sans Serif',
  'MS Reference Specialty',
  'MS Sans Serif',
  'MS Serif',
  'MS UI Gothic',
  'MT Extra',
  'Mukta',
  'Mukta Mahee',
  'MuktaMahee',
  'Mukta Malar',
  'Mukta Vaani',
  'Mukti Narrow',
  'Mukti Narrow Bold',
  'Muli',
  'Multani',
  'Muna',
  'Muna Black',
  'Muna Bold',
  'Muna Regular',
  'Museo',
  'MV Boli',
  'Myanmar',
  'Myanmar MN',
  'Myanmar MN Bold',
  'Myanmar Sangam MN',
  'Myanmar Sangam MN Bold',
  'Myanmar Text',
  'Myanmar Text Bold',
  'Myriad',
  'Myriad Arabic',
  'Myriad Arabic Black',
  'Myriad Arabic Black Italic',
  'Myriad Arabic Bold',
  'Myriad Arabic Bold Italic',
  'Myriad Arabic Italic',
  'Myriad Arabic Light',
  'Myriad Arabic Light Italic',
  'Myriad Arabic Semibold',
  'Myriad Arabic Semibold Italic',
  'Myriad Hebrew',
  'Myriad Pro',
  'Myriad Pro Cond',
  'Myriad Pro Light',
  'Myriad Web Pro',
  'Mystery Quest',
  'Nachlieli CLM',
  'Nada',
  'Nadeem',
  'Nadeem Regular',
  'Nagham',
  'Nakula',
  'NanumBarunGothic',
  'Nanum Brush Script',
  'Nanum Gothic',
  'NanumGothic',
  'NanumGothic Bold',
  'Nanum Gothic Coding',
  'NanumGothicCoding',
  'NanumGothic ExtraBold',
  'NanumGothicExtraBold',
  'NanumGothic Light',
  'Nanum Myeongjo',
  'NanumMyeongjo',
  'NanumMyeongjo Bold',
  'NanumMyeongjo ExtraBold',
  'Nanum Pen Script',
  'Nanum Script',
  'NanumSquare',
  'NanumSquareRound',
  'Narkisim',
  'Navilu',
  'Nazli',
  'Neucha',
  'Neue Haas Grotesk Text Pro',
  'Neue Haas Grotesk Text Pro Bold',
  'Neue Haas Grotesk Text Pro Bold Italic',
  'Neue Haas Grotesk Text Pro Italic',
  'Neue Haas Grotesk Text Pro Medium',
  'Neue Haas Grotesk Text Pro Medium Italic',
  'Neuton',
  'Nevis',
  'Newa',
  'New Peninim MT',
  'New Peninim MT Bold',
  'New Peninim MT Bold Inclined',
  'New Peninim MT Inclined',
  'New Rocker',
  'News Cycle',
  'NewsGoth BT',
  'News Gothic',
  'News Gothic MT',
  'News GothicMT',
  'New Tai Lue',
  'Niagara Engraved',
  'Niagara Solid',
  'Nice',
  'Niconne',
  'Nimbus Mono L',
  'Nimbus Mono PS',
  'Nimbus Roman',
  'Nimbus Roman No9 L',
  'Nimbus Sans',
  'Nimbus Sans L',
  'Nimbus Sans Narrow',
  'Niramit',
  'Nirmala UI',
  'Nirmala UI Bold',
  'Nirmala UI Semilight',
  'Nixie One',
  'NKo',
  'Nobile',
  'Nokora',
  'Norasi',
  'Norican',
  'Nosifer',
  'Notable',
  'Noteworthy',
  'Noteworthy Bold',
  'Noteworthy Light',
  'Nothing You Could Do',
  'Noticia Text',
  'Noto Color Emoji',
  'Noto Emoji',
  'Noto Kufi Arabic',
  'Noto Mono',
  'Noto Music',
  'Noto Naskh Arabic',
  'Noto Naskh Arabic UI',
  'Noto Nastaliq Urdu',
  'Noto Sans',
  'Noto Sans Adlam',
  'Noto Sans Adlam Unjoined',
  'Noto Sans Anatolian Hieroglyphs',
  'Noto Sans Arabic',
  'Noto Sans Arabic UI',
  'Noto Sans Armenian',
  'Noto Sans Avestan',
  'Noto Sans Balinese',
  'Noto Sans Bamum',
  'Noto Sans Bassa Vah',
  'Noto Sans Batak',
  'Noto Sans Bengali',
  'Noto Sans Bengali UI',
  'Noto Sans Bhaiksuki',
  'Noto Sans Brahmi',
  'Noto Sans Buginese',
  'Noto Sans Buhid',
  'Noto Sans Canadian Aboriginal',
  'Noto Sans Carian',
  'Noto Sans Caucasian Albanian',
  'Noto Sans Chakma',
  'Noto Sans Cham',
  'Noto Sans Cherokee',
  'Noto Sans CJK HK',
  'Noto Sans CJK HK Black',
  'Noto Sans CJK HK DemiLight',
  'Noto Sans CJK HK Light',
  'Noto Sans CJK HK Medium',
  'Noto Sans CJK HK Thin',
  'Noto Sans CJK JP',
  'Noto Sans CJK JP Black',
  'Noto Sans CJK JP DemiLight',
  'Noto Sans CJK JP Light',
  'Noto Sans CJK JP Medium',
  'Noto Sans CJK JP Thin',
  'Noto Sans CJK KR',
  'Noto Sans CJK KR Black',
  'Noto Sans CJK KR DemiLight',
  'Noto Sans CJK KR Light',
  'Noto Sans CJK KR Medium',
  'Noto Sans CJK KR Thin',
  'Noto Sans CJK SC',
  'Noto Sans CJK SC Black',
  'Noto Sans CJK SC DemiLight',
  'Noto Sans CJK SC Light',
  'Noto Sans CJK SC Medium',
  'Noto Sans CJK SC Regular',
  'Noto Sans CJK SC Thin',
  'Noto Sans CJK TC',
  'Noto Sans CJK TC Black',
  'Noto Sans CJK TC DemiLight',
  'Noto Sans CJK TC Light',
  'Noto Sans CJK TC Medium',
  'Noto Sans CJK TC Thin',
  'Noto Sans Coptic',
  'Noto Sans Cuneiform',
  'Noto Sans Cypriot',
  'Noto Sans Deseret',
  'Noto Sans Devanagari',
  'Noto Sans Devanagari UI',
  'Noto Sans Display',
  'Noto Sans Duployan',
  'Noto Sans Egyptian Hieroglyphs',
  'Noto Sans Elbasan',
  'Noto Sans Ethiopic',
  'Noto Sans Georgian',
  'Noto Sans Glagolitic',
  'Noto Sans Gothic',
  'Noto Sans Grantha',
  'Noto Sans Gujarati',
  'Noto Sans Gujarati UI',
  'Noto Sans Gunjala Gondi',
  'Noto Sans Gurmukhi',
  'Noto Sans Gurmukhi UI',
  'Noto Sans Hanifi Rohingya',
  'Noto Sans Hanunoo',
  'Noto Sans Hatran',
  'Noto Sans Hebrew',
  'Noto Sans HK',
  'Noto Sans Imperial Aramaic',
  'Noto Sans Inscriptional Pahlavi',
  'Noto Sans Inscriptional Parthian',
  'Noto Sans Javanese',
  'Noto Sans JP',
  'Noto Sans JP Regular',
  'Noto Sans Kaithi',
  'Noto Sans Kannada',
  'Noto Sans Kannada UI',
  'Noto Sans Kayah Li',
  'Noto Sans Kharoshthi',
  'Noto Sans Khmer',
  'Noto Sans Khmer UI',
  'Noto Sans Khojki',
  'Noto Sans Khudawadi',
  'Noto Sans KR',
  'Noto Sans KR Regular',
  'Noto Sans Lao',
  'Noto Sans Lao UI',
  'Noto Sans Lepcha',
  'Noto Sans Limbu',
  'Noto Sans Linear A',
  'Noto Sans Linear B',
  'Noto Sans Lisu',
  'Noto Sans Lycian',
  'Noto Sans Lydian',
  'Noto Sans Mahajani',
  'Noto Sans Malayalam',
  'Noto Sans Malayalam UI',
  'Noto Sans Mandaic',
  'Noto Sans Manichaean',
  'Noto Sans Marchen',
  'Noto Sans Masaram Gondi',
  'Noto Sans Math',
  'Noto Sans Medefaidrin',
  'Noto Sans Meetei Mayek',
  'Noto Sans Mende Kikakui',
  'Noto Sans Meroitic',
  'Noto Sans Miao',
  'Noto Sans Modi',
  'Noto Sans Mongolian',
  'Noto Sans Mono',
  'Noto Sans Mono CJK HK',
  'Noto Sans Mono CJK JP',
  'Noto Sans Mono CJK KR',
  'Noto Sans Mono CJK SC',
  'Noto Sans Mono CJK TC',
  'Noto Sans Mro',
  'Noto Sans Multani',
  'Noto Sans Myanmar',
  'Noto Sans Myanmar UI',
  'Noto Sans Nabataean',
  'Noto Sans Newa',
  'Noto Sans New Tai Lue',
  'Noto Sans NKo',
  'Noto Sans Ogham',
  'Noto Sans Ol Chiki',
  'Noto Sans Old Hungarian',
  'Noto Sans Old Italic',
  'Noto Sans Old North Arabian',
  'Noto Sans Old Permic',
  'Noto Sans Old Persian',
  'Noto Sans Old South Arabian',
  'Noto Sans Old Turkic',
  'Noto Sans Oriya',
  'Noto Sans Oriya UI',
  'Noto Sans Osage',
  'Noto Sans Osmanya',
  'Noto Sans Pahawh Hmong',
  'Noto Sans Palmyrene',
  'Noto Sans Pau Cin Hau',
  'Noto Sans Phags Pa',
  'Noto Sans PhagsPa',
  'Noto Sans Phoenician',
  'Noto Sans Psalter Pahlavi',
  'Noto Sans Rejang',
  'Noto Sans Runic',
  'Noto Sans Samaritan',
  'Noto Sans Saurashtra',
  'Noto Sans SC',
  'Noto Sans SC Regular',
  'Noto Sans Sharada',
  'Noto Sans Shavian',
  'Noto Sans Siddham',
  'Noto Sans Sinhala',
  'Noto Sans Sinhala UI',
  'Noto Sans Sora Sompeng',
  'Noto Sans Soyombo',
  'Noto Sans Sundanese',
  'Noto Sans Syloti Nagri',
  'Noto Sans Symbols',
  'Noto Sans Symbols 2',
  'Noto Sans Syriac',
  'Noto Sans Syriac Eastern',
  'Noto Sans Syriac Estrangela',
  'Noto Sans Syriac Western',
  'Noto Sans Tagalog',
  'Noto Sans Tagbanwa',
  'Noto Sans Tai Le',
  'Noto Sans Tai Tham',
  'Noto Sans Tai Viet',
  'Noto Sans Takri',
  'Noto Sans Tamil',
  'Noto Sans Tamil UI',
  'Noto Sans TC',
  'Noto Sans TC Regular',
  'Noto Sans Telugu',
  'Noto Sans Telugu UI',
  'Noto Sans Thaana',
  'Noto Sans Thai',
  'Noto Sans Thai UI',
  'Noto Sans Tibetan',
  'Noto Sans Tifinagh',
  'Noto Sans Tifinagh Adrar',
  'Noto Sans Tifinagh Agraw Imazighen',
  'Noto Sans Tifinagh Ahaggar',
  'Noto Sans Tifinagh Air',
  'Noto Sans Tifinagh APT',
  'Noto Sans Tifinagh Azawagh',
  'Noto Sans Tifinagh Ghat',
  'Noto Sans Tifinagh Hawad',
  'Noto Sans Tifinagh Rhissa Ixa',
  'Noto Sans Tifinagh SIL',
  'Noto Sans Tifinagh Tawellemmet',
  'Noto Sans Tirhuta',
  'Noto Sans Ugaritic',
  'Noto Sans UI',
  'Noto Sans Vai',
  'Noto Sans Wancho',
  'Noto Sans Warang Citi',
  'Noto Sans Yi',
  'Noto Sans Zanabazar Square',
  'Noto Sans Zawgyi',
  'Noto Serif',
  'Noto Serif Ahom',
  'Noto Serif Armenian',
  'Noto Serif Balinese',
  'Noto Serif Bengali',
  'Noto Serif CJK JP',
  'Noto Serif CJK JP Black',
  'Noto Serif CJK JP ExtraLight',
  'Noto Serif CJK JP Light',
  'Noto Serif CJK JP Medium',
  'Noto Serif CJK JP SemiBold',
  'Noto Serif CJK KR',
  'Noto Serif CJK KR Black',
  'Noto Serif CJK KR ExtraLight',
  'Noto Serif CJK KR Light',
  'Noto Serif CJK KR Medium',
  'Noto Serif CJK KR SemiBold',
  'Noto Serif CJK SC',
  'Noto Serif CJK SC Black',
  'Noto Serif CJK SC ExtraLight',
  'Noto Serif CJK SC Light',
  'Noto Serif CJK SC Medium',
  'Noto Serif CJK SC SemiBold',
  'Noto Serif CJK TC',
  'Noto Serif CJK TC Black',
  'Noto Serif CJK TC ExtraLight',
  'Noto Serif CJK TC Light',
  'Noto Serif CJK TC Medium',
  'Noto Serif CJK TC SemiBold',
  'Noto Serif Devanagari',
  'Noto Serif Display',
  'Noto Serif Dogra',
  'Noto Serif Ethiopic',
  'Noto Serif Georgian',
  'Noto Serif Grantha',
  'Noto Serif Gujarati',
  'Noto Serif Gurmukhi',
  'Noto Serif Hebrew',
  'Noto Serif Hmong Nyiakeng',
  'Noto Serif JP',
  'Noto Serif Kannada',
  'Noto Serif Khmer',
  'Noto Serif Khojki',
  'Noto Serif KR',
  'Noto Serif Lao',
  'Noto Serif Malayalam',
  'Noto Serif Myanmar',
  'Noto Serif NP Hmong',
  'Noto Serif SC',
  'Noto Serif Sinhala',
  'Noto Serif Tamil',
  'Noto Serif Tamil Slanted',
  'Noto Serif TC',
  'Noto Serif Telugu',
  'Noto Serif Thai',
  'Noto Serif Tibetan',
  'Noto Serif Yezidi',
  'Nova Cut',
  'Nova Flat',
  'Nova Mono',
  'Nova Oval',
  'Nova Round',
  'Nova Script',
  'Nova Slim',
  'Nova Square',
  'NP Hmong',
  'NSimSun',
  'NTR',
  'Nueva Std',
  'Nueva Std Cond',
  'Numans',
  'Nunito',
  'Nunito Sans',
  'Nuosu SIL',
  'Nyala',
  'OCR-A BT',
  'OCR-B 10 BT',
  'OCR A Extended',
  'OCR A Std',
  'Odor Mean Chey',
  'Offside',
  'Ol Chiki',
  'Old Century',
  'Oldenburg',
  'Old English Text MT',
  'Old Standard TT',
  'Oleo Script',
  'Oleo Script Swash Caps',
  'Onyx',
  'Onyx BT',
  'Open Sans',
  'Open Sans Condensed',
  'OpenSymbol',
  'Optima',
  'Optima Bold',
  'Optima Bold Italic',
  'Optima ExtraBlack',
  'Optima Italic',
  'Optima Regular',
  'Oranienbaum',
  'Orator Std',
  'Orbitron',
  'Oregano',
  'ori1Uni',
  'Orienta',
  'Original Surfer',
  'Oriya',
  'Oriya MN',
  'Oriya MN Bold',
  'Oriya Sangam MN',
  'Oriya Sangam MN Bold',
  'Osage',
  'Osaka',
  'Osaka-Mono',
  'Osaka Mono',
  'Osmanya',
  'Ostorah',
  'Oswald',
  'Ouhod',
  'Ouhod-Bold',
  'Ouverture script',
  'Overlock',
  'Overlock SC',
  'Overpass',
  'Overpass Mono',
  'Over the Rainbow',
  'Ovo',
  'Oxygen',
  'Oxygen Mono',
  'OzHandicraft BT',
  'P052',
  'Pacifico',
  'Padauk',
  'Padauk Book',
  'padmaa',
  'padmaa-Bold.1.1',
  'padmmaa',
  'Pagul',
  'Pahawh Hmong',
  'PakTypeNaqsh',
  'PakType Naskh Basic',
  'PakTypeTehreer',
  'Palace Script MT',
  'Palanquin',
  'Palanquin Dark',
  'Palatino',
  'Palatino Bold',
  'Palatino Bold Italic',
  'Palatino Italic',
  'Palatino Linotype',
  'Palatino Linotype Bold',
  'Palatino Linotype Bold Italic',
  'Palatino Linotype Italic',
  'Pangolin',
  'PanRoman',
  'Paprika',
  'Papyrus',
  'Papyrus Condensed',
  'Parchment',
  'Parisienne',
  'Party LET',
  'Passero One',
  'Passion One',
  'Pathway Gothic One',
  'Patrick Hand',
  'Patrick Hand SC',
  'Pattaya',
  'Patua One',
  'Pau Cin Hau',
  'Pavanam',
  'Paytone One',
  'PCMyungjo',
  'PCMyungjo Regular',
  'Peddana',
  'Pegasus',
  'Peralta',
  'Permanent Marker',
  'Perpetua',
  'Perpetua Titling MT',
  'PetitaBold',
  'Petit Formal Script',
  'Petra',
  'Petrona',
  'Phetsarath OT',
  'Philosopher',
  'Phosphate',
  'Phosphate Inline',
  'Phosphate Solid',
  'Pickwick',
  'Piedra',
  'PilGi',
  'PilGi Regular',
  'PingFang HK',
  'PingFang HK Light',
  'PingFang HK Medium',
  'PingFang HK Regular',
  'PingFang HK Semibold',
  'PingFang HK Thin',
  'PingFang HK Ultralight',
  'PingFang SC',
  'PingFang SC Light',
  'PingFang SC Medium',
  'PingFang SC Regular',
  'PingFang SC Semibold',
  'PingFang SC Thin',
  'PingFang SC Ultralight',
  'PingFang TC',
  'PingFang TC Light',
  'PingFang TC Medium',
  'PingFang TC Regular',
  'PingFang TC Semibold',
  'PingFang TC Thin',
  'PingFang TC Ultralight',
  'Pinyon Script',
  'Pirata One',
  'Plantagenet Cherokee',
  'Plaster',
  'Play',
  'Playball',
  'Playbill',
  'Playfair Display',
  'Playfair Display SC',
  'PMingLiU',
  'PMingLiU-ExtB',
  'Podkova',
  'Poiret One',
  'Poller One',
  'Poly',
  'Pompiere',
  'Pontano Sans',
  'Poor Richard',
  'Poor Story',
  'Poplar Std',
  'Poppins',
  'Port Lligat Sans',
  'Port Lligat Slab',
  'Poster',
  'PosterBodoni BT',
  'Pothana2000',
  'Pragati Narrow',
  'Prata',
  'Preahvihear',
  'Press Start 2P',
  'Prestige Elite Std',
  'Pridi',
  'Princess Sofia',
  'PRINCETOWN LET',
  'Pristina',
  'Prociono',
  'Prompt',
  'Prosto One',
  'Proxy 1',
  'Proxy 2',
  'Proxy 3',
  'Proxy 4',
  'Proxy 5',
  'Proxy 6',
  'Proxy 7',
  'Proxy 8',
  'Proxy 9',
  'Proza Libre',
  'PSL Ornanong Pro',
  'PTBarnum BT',
  'PT Mono',
  'PT Mono Bold',
  'PT Sans',
  'PT Sans Bold',
  'PT Sans Bold Italic',
  'PT Sans Caption',
  'PT Sans Caption Bold',
  'PT Sans Italic',
  'PT Sans Narrow',
  'PT Sans Narrow Bold',
  'PT Serif',
  'PT Serif Bold',
  'PT Serif Bold Italic',
  'PT Serif Caption',
  'PT Serif Caption Italic',
  'PT Serif Italic',
  'Public Sans',
  'Purisa',
  'Puritan',
  'Purple Purse',
  'Pythagoras',
  'Quando',
  'Quantico',
  'Quattrocento',
  'Quattrocento Sans',
  'Questrial',
  'Quicksand',
  'Quicksand Light',
  'Quicksand Medium',
  'Quintessential',
  'Qwigley',
  'Raanana',
  'Raanana Bold',
  'Raavi',
  'Raavi Bold',
  'Rachana',
  'Racing Sans One',
  'Radley',
  'Rage Italic',
  'RaghuMalayalam',
  'RaghuMalayalamSans',
  'Rajdhani',
  'Rakkas',
  'Raleway',
  'Raleway Dots',
  'Ramabhadra',
  'Ramaraja',
  'Rambla',
  'Rammetto One',
  'Ranchers',
  'Rancho',
  'Ranga',
  'Rasa',
  'Rasa Light',
  'Rasa Medium',
  'Rasa SemiBold',
  'Rasheeq',
  'Rasheeq-Bold',
  'Rationale',
  'Ravie',
  'Ravi Prakash',
  'Red Hat Display',
  'Red Hat Text',
  'Redressed',
  'Reem Kufi',
  'Reenie Beanie',
  'Rehan',
  'Rejang',
  'Rekha',
  'Revalia',
  'Rhodium Libre',
  'Ribbon131 Bd BT',
  'Ribeye',
  'Ribeye Marrow',
  'Righteous',
  'Risque',
  'Roboto',
  'Roboto Condensed',
  'Roboto Mono',
  'Roboto Slab',
  'Rochester',
  'Rock Salt',
  'Rockwell',
  'Rockwell Bold',
  'Rockwell Bold Italic',
  'Rockwell Condensed',
  'Rockwell Extra Bold',
  'Rockwell Italic',
  'Rockwell Nova',
  'Rockwell Nova Bold',
  'Rockwell Nova Bold Italic',
  'Rockwell Nova Cond',
  'Rockwell Nova Cond Bold',
  'Rockwell Nova Cond Bold Italic',
  'Rockwell Nova Cond Italic',
  'Rockwell Nova Cond Light',
  'Rockwell Nova Cond Light Italic',
  'Rockwell Nova Extra Bold',
  'Rockwell Nova Extra Bold Italic',
  'Rockwell Nova Italic',
  'Rockwell Nova Light Italic',
  'Rockwell Nova Rockwell',
  'Rod',
  'Rokkitt',
  'Roman',
  'RomanC',
  'RomanD',
  'Romanesco',
  'RomanS',
  'RomanT',
  'Romantic',
  'Ropa Sans',
  'Rosario',
  'Rosarivo',
  'Rosewood Std Regular',
  'Rouge Script',
  'Rozha One',
  'rsfs10',
  'Rubik',
  'Rubik Mono One',
  'Ruda',
  'Rufina',
  'Ruge Boogie',
  'Ruluko',
  'Rum Raisin',
  'Runic',
  'Ruslan Display',
  'Russo One',
  'Ruthie',
  'Rye',
  'Saab',
  'Sacramento',
  'Sahadeva',
  'Sahitya',
  'Sail',
  'Saira',
  'Saira Condensed',
  'Saira Extra Condensed',
  'Saira Semi Condensed',
  'Saira Stencil One',
  'Sakkal Majalla',
  'Sakkal Majalla Bold',
  'Salem',
  'Salsa',
  'Samanata',
  'Samaritan',
  'SamsungKorean_v2.0',
  'Samyak Devanagari',
  'Samyak Gujarati',
  'Samyak Malayalam',
  'Samyak Tamil',
  'Sana',
  'Sana Regular',
  'Sanchez',
  'Sancreek',
  'Sansita',
  'Sanskrit Text',
  'SansSerif',
  'Sans Serif Collection',
  'Santa Fe LET',
  'Sarabun',
  'Sarai',
  'Sarala',
  'Sarina',
  'Sarpanch',
  'Sathu',
  'Satisfy',
  'Saurashtra',
  'Savoye LET',
  'Savoye LET Plain:1.0',
  'Savoye LET Plain CC.:1.0',
  'Sawarabi Gothic',
  'Sawarabi Mincho',
  'Sawasdee',
  'Scada',
  'Sceptre',
  'Scheherazade',
  'Schoolbell',
  'Scope One',
  'Script',
  'ScriptC',
  'Scriptina',
  'Script MT Bold',
  'ScriptS',
  'Seaweed Script',
  'SEC CJK JP',
  'SEC CJK KR',
  'SEC CJK SC',
  'SEC CJK TC',
  'SEC Mono CJK JP',
  'SEC Mono CJK KR',
  'SEC Mono CJK SC',
  'SEC Mono CJK TC',
  'Secular One',
  'Sedgwick Ave',
  'Sedgwick Ave Display',
  'Segoe Fluent Icons',
  'Segoe MDL2 Assets',
  'Segoe Print',
  'Segoe Print Bold',
  'Segoe Pseudo',
  'Segoe Script',
  'Segoe Script Bold',
  'Segoe UI',
  'Segoe UI Black',
  'Segoe UI Black Italic',
  'Segoe UI Bold',
  'Segoe UI Bold Italic',
  'Segoe UI Emoji',
  'Segoe UI Historic',
  'Segoe UI Italic',
  'Segoe UI Light',
  'Segoe UI Light Italic',
  'Segoe UI Semibold',
  'Segoe UI Semibold Italic',
  'Segoe UI Semilight',
  'Segoe UI Semilight Italic',
  'Segoe UI Symbol',
  'Segoe UI Variable Display',
  'Segoe UI Variable Small',
  'Segoe UI Variable Text',
  'Seravek',
  'Seravek Bold',
  'Seravek Bold Italic',
  'Seravek ExtraLight',
  'Seravek ExtraLight Italic',
  'Seravek Italic',
  'Seravek Light',
  'Seravek Light Italic',
  'Seravek Medium',
  'Seravek Medium Italic',
  'Serifa',
  'Serifa BT',
  'Serifa Th BT',
  'Sevillana',
  'Seymour One',
  'Shado',
  'Shadows Into Light',
  'Shadows Into Light Two',
  'Shanti',
  'Sharada',
  'Share',
  'Share Tech',
  'Share Tech Mono',
  'Sharjah',
  'Shavian',
  'ShelleyVolante BT',
  'Sherwood',
  'Shofar',
  'Shojumaru',
  'Shonar Bangla',
  'Shonar Bangla Bold',
  'Short Stack',
  'Showcard Gothic',
  'Shree Devanagari 714',
  'Shree Devanagari 714 Bold',
  'Shree Devanagari 714 Bold Italic',
  'Shree Devanagari 714 Italic',
  'Shrikhand',
  'Shruti',
  'Shruti Bold',
  'Siemreap',
  'Sigmar One',
  'Signboard',
  'Signika',
  'Signika Negative',
  'SignPainter',
  'SignPainter-HouseScript',
  'SignPainter-HouseScript Semibold',
  'Silkscreen',
  'Silom',
  'SimHei',
  'Simonetta',
  'Simple CLM',
  'Simplex',
  'Simplified Arabic',
  'Simplified Arabic Bold',
  'Simplified Arabic Fixed',
  'SimSun',
  'SimSun-ExtB',
  'Sindbad',
  'Single Day',
  'Sinhala',
  'Sinhala MN',
  'Sinhala MN Bold',
  'Sinhala Sangam MN',
  'Sinhala Sangam MN Bold',
  'Sintony',
  'Sirin Stencil',
  'Sitka',
  'Sitka Banner',
  'Sitka Banner Bold',
  'Sitka Banner Bold Italic',
  'Sitka Banner Italic',
  'Sitka Display',
  'Sitka Display Bold',
  'Sitka Display Bold Italic',
  'Sitka Display Italic',
  'Sitka Heading',
  'Sitka Heading Bold',
  'Sitka Heading Bold Italic',
  'Sitka Heading Italic',
  'Sitka Small',
  'Sitka Small Bold',
  'Sitka Small Bold Italic',
  'Sitka Small Italic',
  'Sitka Subheading',
  'Sitka Subheading Bold',
  'Sitka Subheading Bold Italic',
  'Sitka Subheading Italic',
  'Sitka Text',
  'Sitka Text Bold',
  'Sitka Text Bold Italic',
  'Sitka Text Italic',
  'Six Caps',
  'Sketch Rockwell',
  'Skia',
  'Skia Black',
  'Skia Black Condensed',
  'Skia Black Extended',
  'Skia Bold',
  'Skia Condensed',
  'Skia Extended',
  'Skia Light',
  'Skia Light Condensed',
  'Skia Light Extended',
  'Skia Regular',
  'Skranji',
  'Slabo 13px',
  'Slabo 27px',
  'Slackey',
  'Small Fonts',
  'Smokum',
  'Smythe',
  'Snap ITC',
  'Snell Roundhand',
  'Snell Roundhand Black',
  'Snell Roundhand Bold',
  'Sniglet',
  'Snippet',
  'Snowburst One',
  'Socket',
  'Sofadi One',
  'Sofia',
  'Song',
  'Song Myung',
  'Songti SC',
  'Songti SC Black',
  'Songti SC Bold',
  'Songti SC Light',
  'Songti SC Regular',
  'Songti TC',
  'Songti TC Bold',
  'Songti TC Light',
  'Songti TC Regular',
  'Sonsie One',
  'Sora Sompeng',
  'Sorts Mill Goudy',
  'Source Code Pro',
  'Source Code Pro Black',
  'Source Code Pro ExtraLight',
  'Source Code Pro Light',
  'Source Code Pro Medium',
  'Source Code Pro Semibold',
  'Source Han Sans CN',
  'Source Han Sans CN Bold',
  'Source Han Sans CN ExtraLight',
  'Source Han Sans CN Heavy',
  'Source Han Sans CN Light',
  'Source Han Sans CN Medium',
  'Source Han Sans CN Normal',
  'Source Han Sans TW',
  'Source Han Serif CN',
  'Source Han Serif TW',
  'Source Sans Pro',
  'Source Serif Pro',
  'Souvenir Lt BT',
  'Soyombo',
  'Space Mono',
  'Special Elite',
  'Spectral',
  'Spectral SC',
  'Spicy Rice',
  'Spinnaker',
  'Spirax',
  'Squada One',
  'Square721 BT',
  'Sree Krushnadevaraya',
  'Sriracha',
  'Srisakdi',
  'Staatliches',
  'Staccato222 BT',
  'Stalemate',
  'Stalinist One',
  'Stam Ashkenaz CLM',
  'Stam Sefarad CLM',
  'Standard Symbols L',
  'Standard Symbols PS',
  'Stardos Stencil',
  'Steamer',
  'Stencil',
  'Stencil Std',
  'STFangsong',
  'STHeiti',
  'Stint Ultra Condensed',
  'Stint Ultra Expanded',
  'STIX',
  'STIXGeneral',
  'STIXGeneral-Bold',
  'STIXGeneral-BoldItalic',
  'STIXGeneral-Italic',
  'STIXGeneral-Regular',
  'STIXIntegralsD',
  'STIXIntegralsD-Bold',
  'STIXIntegralsD-Regular',
  'STIXIntegralsSm',
  'STIXIntegralsSm-Bold',
  'STIXIntegralsSm-Regular',
  'STIXIntegralsUp',
  'STIXIntegralsUp-Bold',
  'STIXIntegralsUp-Regular',
  'STIXIntegralsUpD',
  'STIXIntegralsUpD-Bold',
  'STIXIntegralsUpD-Regular',
  'STIXIntegralsUpSm',
  'STIXIntegralsUpSm-Bold',
  'STIXIntegralsUpSm-Regular',
  'STIX Math',
  'STIXNonUnicode',
  'STIXNonUnicode-Bold',
  'STIXNonUnicode-BoldItalic',
  'STIXNonUnicode-Italic',
  'STIXNonUnicode-Regular',
  'STIXSizeFiveSym',
  'STIXSizeFiveSym-Regular',
  'STIXSizeFourSym',
  'STIXSizeFourSym-Bold',
  'STIXSizeFourSym-Regular',
  'STIXSizeOneSym',
  'STIXSizeOneSym-Bold',
  'STIXSizeOneSym-Regular',
  'STIXSizeThreeSym',
  'STIXSizeThreeSym-Bold',
  'STIXSizeThreeSym-Regular',
  'STIXSizeTwoSym',
  'STIXSizeTwoSym-Bold',
  'STIXSizeTwoSym-Regular',
  'STIX Two Math',
  'STIX Two Text',
  'STIX Two Text Bold',
  'STIX Two Text Bold Italic',
  'STIX Two Text Italic',
  'STIXVariants',
  'STIXVariants-Bold',
  'STIXVariants-Regular',
  'STKaiti',
  'Stoke',
  'Storybook',
  'Strait',
  'STSong',
  'STXihei',
  'Stylish',
  'Styllo',
  'Stylus BT',
  'Subway',
  'Sue Ellen Francisco',
  'Suez One',
  'Sukhumvit Set',
  'Sukhumvit Set Bold',
  'Sukhumvit Set Light',
  'Sukhumvit Set Medium',
  'Sukhumvit Set Semi Bold',
  'Sukhumvit Set Text',
  'Sukhumvit Set Thin',
  'Sumana',
  'Sundanese',
  'Sunflower',
  'Sunshiney',
  'Superclarendon',
  'Superclarendon Black',
  'Superclarendon Black Italic',
  'Superclarendon Bold',
  'Superclarendon Bold Italic',
  'Superclarendon Italic',
  'Superclarendon Light',
  'Superclarendon Light Italic',
  'Superclarendon Regular',
  'SuperFrench',
  'Supermercado One',
  'Sura',
  'Suranna',
  'Suravaram',
  'Suruma',
  'Suwannaphum',
  'Swanky and Moo Moo',
  'Swis721 BdCnOul BT',
  'Swis721 BdOul BT',
  'Swis721 Blk BT',
  'Swis721 BlkCn BT',
  'Swis721 BlkEx BT',
  'Swis721 BlkOul BT',
  'Swis721 BT',
  'Swis721 Cn BT',
  'Swis721 Ex BT',
  'Swis721 Hv BT',
  'Swis721 Lt BT',
  'Swis721 LtCn BT',
  'Swis721 LtEx BT',
  'Swiss911 XCm BT',
  'Syastro',
  'Sylfaen',
  'Syloti Nagri',
  'Symap',
  'Symath',
  'Symbol',
  'Symbola',
  'Symbols',
  'Symbols 2',
  'Symeteo',
  'Symusic',
  'Synchro LET',
  'Syncopate',
  'Syriac',
  'System',
  'System Font Bold',
  'System Font Regular',
  'Tagalog',
  'Tagbanwa',
  'Tahoma',
  'Tahoma Bold',
  'Tahoma Negreta',
  'Tai Le',
  'Tai Tham',
  'Tai Viet',
  'Tajawal',
  'TakaoPGothic',
  'Takri',
  'Tamil',
  'Tamil MN',
  'Tamil MN Bold',
  'Tamil Sangam MN',
  'Tamil Sangam MN Bold',
  'Tangerine',
  'Taprom',
  'Tarablus',
  'Tauri',
  'Taviraj',
  'Teams',
  'TeamViewer8',
  'Technic',
  'Technical',
  'TechnicBold',
  'TechnicLite',
  'Teko',
  'Tekton Pro',
  'Tekton Pro Cond',
  'Tekton Pro Ext',
  'Teletype',
  'Telex',
  'Telugu',
  'Telugu MN',
  'Telugu MN Bold',
  'Telugu Sangam MN',
  'Telugu Sangam MN Bold',
  'Tempus Sans ITC',
  'Tenali Ramakrishna',
  'Tenor Sans',
  'Terminal',
  'TeX Gyre Bonum Math',
  'TeX Gyre Pagella Math',
  'TeX Gyre Schola',
  'TeX Gyre Termes Math',
  'Text Me One',
  'Thaana',
  'Thabit',
  'Thasadith',
  'The Girl Next Door',
  'Tholoth',
  'Thonburi',
  'Thonburi Bold',
  'Thonburi Light',
  'Tibetan',
  'Tibetan Machine Uni',
  'Tienne',
  'Tifinagh',
  'Tifinagh Adrar',
  'Tifinagh Agraw Imazighen',
  'Tifinagh Ahaggar',
  'Tifinagh Air',
  'Tifinagh APT',
  'Tifinagh Azawagh',
  'Tifinagh Ghat',
  'Tifinagh Hawad',
  'Tifinagh Rhissa Ixa',
  'Tifinagh SIL',
  'Tifinagh Tawellemmet',
  'Tillana',
  'Times',
  'Times Bold',
  'Times Bold Italic',
  'Times CY',
  'Times Italic',
  'Times LT MM',
  'Times New Roman',
  'Times New Roman Baltic',
  'Times New Roman Bold',
  'Times New Roman Bold Italic',
  'Times New Roman CE',
  'Times New Roman Cyr',
  'Times New Roman Greek',
  'Times New Roman Italic',
  'Times New Roman PS',
  'Times New Roman TUR',
  'Times Roman',
  'Timmana',
  'Tinos',
  'Tirhuta',
  'Titan One',
  'Titillium Web',
  'Titr',
  'Tlwg Mono',
  'TlwgMono',
  'Tlwg Typewriter',
  'TlwgTypewriter',
  'Tlwg Typist',
  'Tlwg Typo',
  'Tms Rmn',
  'Tomorrow',
  'Toppan Bunkyu Gothic',
  'Toppan Bunkyu Gothic Demibold',
  'Toppan Bunkyu Gothic Regular',
  'Toppan Bunkyu Midashi Gothic',
  'Toppan Bunkyu Midashi Gothic Extrabold',
  'Toppan Bunkyu Midashi Mincho',
  'Toppan Bunkyu Midashi Mincho Extrabold',
  'Toppan Bunkyu Mincho',
  'Toppan Bunkyu Mincho Regular',
  'Trade Winds',
  'Traditional Arabic',
  'Traditional Arabic Bold',
  'Trajan',
  'Trajan Pro',
  'Trattatello',
  'Trebuchet MS',
  'Trebuchet MS Bold',
  'Trebuchet MS Bold Italic',
  'Trebuchet MS Italic',
  'Trirong',
  'Tristan',
  'Trocchi',
  'Trochut',
  'Trykker',
  'Tsukushi A Round Gothic',
  'Tsukushi A Round Gothic Bold',
  'Tsukushi A Round Gothic Regular',
  'Tsukushi B Round Gothic',
  'Tsukushi B Round Gothic Bold',
  'Tsukushi B Round Gothic Regular',
  'Tubular',
  'Tulpen One',
  'Tunga',
  'Tunga Bold',
  'Turret Road',
  'Tw Cen MT',
  'Tw Cen MT Condensed',
  'Tw Cen MT Condensed Extra Bold',
  'Twemoji Mozilla',
  'Txt',
  'TypoUpright BT',
  'Ubuntu',
  'Ubuntu Condensed',
  'Ubuntu Light',
  'Ubuntu Mono',
  'Ubuntu Thin',
  'UD Digi Kyokasho',
  'UD Digi Kyokasho N-B',
  'UD Digi Kyokasho N-R',
  'UD Digi Kyokasho NK-B',
  'UD Digi Kyokasho NK-R',
  'UD Digi Kyokasho NP-B',
  'UD Digi Kyokasho NP-R',
  'UKIJ 3D',
  'UKIJ Basma',
  'UKIJ Bom',
  'UKIJ Chechek',
  'UKIJ Chiwer Kesme',
  'UKIJ CJK',
  'UKIJ Diwani',
  'UKIJ Diwani Kawak',
  'UKIJ Diwani Tom',
  'UKIJ Diwani Yantu',
  'UKIJ Ekran',
  'UKIJ Elipbe',
  'UKIJ Elipbe_Chekitlik',
  'UKIJ Esliye',
  'UKIJ Esliye Chiwer',
  'UKIJ Esliye Neqish',
  'UKIJ Esliye Qara',
  'UKIJ Esliye Tom',
  'UKIJ Imaret',
  'UKIJ Inchike',
  'UKIJ Jelliy',
  'UKIJ Junun',
  'UKIJ Kawak',
  'UKIJ Kawak 3D',
  'UKIJ Kesme',
  'UKIJ Kesme Tuz',
  'UKIJ Kufi',
  'UKIJ Kufi 3D',
  'UKIJ Kufi Chiwer',
  'UKIJ Kufi Gul',
  'UKIJ Kufi Kawak',
  'UKIJ Kufi Tar',
  'UKIJ Kufi Uz',
  'UKIJ Kufi Yay',
  'UKIJ Kufi Yolluq',
  'UKIJ Mejnun',
  'UKIJ Mejnuntal',
  'UKIJ Merdane',
  'UKIJ Moy Qelem',
  'UKIJ Nasq',
  'UKIJ Nasq Zilwa',
  'UKIJ Orqun Basma',
  'UKIJ Orqun Yazma',
  'UKIJ Orxun-Yensey',
  'UKIJ Qara',
  'UKIJ Qolyazma',
  'UKIJ Qolyazma Tez',
  'UKIJ Qolyazma Tuz',
  'UKIJ Qolyazma Yantu',
  'UKIJ Ruqi',
  'UKIJ Saet',
  'UKIJ Sulus',
  'UKIJ Sulus Tom',
  'UKIJ Teng',
  'UKIJ Tiken',
  'UKIJ Title',
  'UKIJ Tor',
  'UKIJ Tughra',
  'UKIJ Tuz',
  'UKIJ Tuz Basma',
  'UKIJ Tuz Gezit',
  'UKIJ Tuz Kitab',
  'UKIJ Tuz Neqish',
  'UKIJ Tuz Qara',
  'UKIJ Tuz Tom',
  'UKIJ Tuz Tor',
  'UKIJ Zilwa',
  'UKIJ_Mac Basma',
  'UKIJ_Mac Ekran',
  'Ultra',
  'Umpush',
  'UnBatang',
  'Uncial Antiqua',
  'Underdog',
  'UnDotum',
  'Unica One',
  'Unicorn',
  'Unifont',
  'Unifont CSUR',
  'Unifont Upper',
  'UnifrakturCook',
  'UnifrakturMaguntia',
  'Unikurd Web',
  'Univers',
  'UniversalMath1 BT',
  'Univers CE 55 Medium',
  'Univers Condensed',
  'Unkempt',
  'Unlock',
  'Unna',
  'Urdu Typesetting',
  'Urdu Typesetting Bold',
  'Uroob',
  'URW Bookman',
  'URW Bookman L',
  'URW Chancery L',
  'URW Gothic',
  'URW Gothic L',
  'URW Palladio L',
  'utkal',
  'Utopia',
  'Utsaah',
  'Utsaah Bold',
  'Utsaah Bold Italic',
  'Utsaah Italic',
  'Vagabond',
  'Vai',
  'Vampiro One',
  'Vani',
  'Vani Bold',
  'Varela',
  'Varela Round',
  'Vast Shadow',
  'Vemana2000',
  'Verdana',
  'Verdana Bold',
  'Verdana Bold Italic',
  'Verdana Italic',
  'Verdana Pro',
  'Verdana Pro Black',
  'Verdana Pro Black Italic',
  'Verdana Pro Bold',
  'Verdana Pro Bold Italic',
  'Verdana Pro Cond',
  'Verdana Pro Cond Black',
  'Verdana Pro Cond Black Italic',
  'Verdana Pro Cond Bold',
  'Verdana Pro Cond Bold Italic',
  'Verdana Pro Cond Italic',
  'Verdana Pro Cond Light',
  'Verdana Pro Cond Light Italic',
  'Verdana Pro Cond SemiBold',
  'Verdana Pro Cond SemiBold Italic',
  'Verdana Pro Italic',
  'Verdana Pro Light',
  'Verdana Pro Light Italic',
  'Verdana Pro SemiBold',
  'Verdana Pro SemiBold Italic',
  'Vesper Libre',
  'Vibes',
  'Vibur',
  'Vidaloka',
  'Viga',
  'Vijaya',
  'Vijaya Bold',
  'Viner Hand ITC',
  'Vineta BT',
  'VisualUI',
  'Vivaldi',
  'Vladimir Script',
  'VL Gothic',
  'VL PGothic',
  'Voces',
  'Volkhov',
  'Vollkorn',
  'Vollkorn SC',
  'Voltaire',
  'Vrinda',
  'Vrinda Bold',
  'VT323',
  'Waiting for the Sunrise',
  'Wallpoet',
  'Walter Turncoat',
  'Wancho',
  'Warang Citi',
  'Waree',
  'Warnes',
  'Waseem',
  'Waseem Light',
  'Waseem Regular',
  'wasy10',
  'Wawati SC',
  'Wawati SC Regular',
  'Wawati TC',
  'Wawati TC Regular',
  'Webdings',
  'Weibei SC',
  'Weibei SC Bold',
  'Weibei TC',
  'Weibei TC Bold',
  'Wellfleet',
  'Wendy One',
  'WenQuanYi Micro Hei',
  'WenQuanYi Micro Hei Mono',
  'WenQuanYi Zen Hei',
  'WenQuanYi Zen Hei Mono',
  'WenQuanYi Zen Hei Sharp',
  'Westminster',
  'Whitney',
  'Wide Latin',
  'Wingdings',
  'Wingdings 2',
  'Wingdings 3',
  'Wire One',
  'Work Sans',
  'WP ArabicScript Sihafa',
  'WP Arabic Sihafa',
  'WP CyrillicA',
  'WP CyrillicB',
  'WP Greek Century',
  'WP Greek Courier',
  'WP Greek Helve',
  'WP Hebrew David',
  'WP MultinationalA Courier',
  'WP MultinationalA Helve',
  'WP MultinationalA Roman',
  'WP MultinationalB Courier',
  'WP MultinationalB Helve',
  'WP MultinationalB Roman',
  'WST_Czec',
  'WST_Engl',
  'WST_Fren',
  'WST_Germ',
  'WST_Ital',
  'WST_Span',
  'WST_Swed',
  'Xingkai SC',
  'Xingkai SC Bold',
  'Xingkai SC Light',
  'Xingkai TC',
  'Xingkai TC Bold',
  'Xingkai TC Light',
  'XITS Math',
  'Yanone Kaffeesatz',
  'Yantramanav',
  'Yatra One',
  'Yehuda CLM',
  'Yellowtail',
  'Yeon Sung',
  'Yeseva One',
  'Yesteryear',
  'Yezidi',
  'Yi',
  'Yrsa',
  'Yrsa Light',
  'Yrsa Medium',
  'Yrsa SemiBold',
  'Yuanti SC',
  'Yuanti SC Bold',
  'Yuanti SC Light',
  'Yuanti SC Regular',
  'Yuanti TC',
  'Yuanti TC Bold',
  'Yuanti TC Light',
  'Yuanti TC Regular',
  'Yu Gothic',
  'YuGothic',
  'Yu Gothic Bold',
  'YuGothic Bold',
  'Yu Gothic Light',
  'Yu Gothic Medium',
  'YuGothic Medium',
  'Yu Gothic Regular',
  'Yu Gothic UI',
  'Yu Gothic UI Bold',
  'Yu Gothic UI Light',
  'Yu Gothic UI Regular',
  'Yu Gothic UI Semibold',
  'Yu Gothic UI Semilight',
  'YuKyokasho',
  'YuKyokasho Bold',
  'YuKyokasho Medium',
  'YuKyokasho Yoko',
  'YuKyokasho Yoko Bold',
  'YuKyokasho Yoko Medium',
  'Yu Mincho',
  'YuMincho',
  'YuMincho +36p Kana',
  'YuMincho +36p Kana Demibold',
  'YuMincho +36p Kana Extrabold',
  'YuMincho +36p Kana Medium',
  'Yu Mincho Demibold',
  'YuMincho Demibold',
  'YuMincho Extrabold',
  'Yu Mincho Light',
  'YuMincho Medium',
  'Yu Mincho Regular',
  'Yuppy SC',
  'Yuppy SC Regular',
  'Yuppy TC',
  'Yuppy TC Regular',
  'Z003',
  'Zanabazar Square',
  'Zapf Dingbats',
  'ZapfEllipt BT',
  'ZapfHumnst BT',
  'ZapfHumnst Dm BT',
  'Zapfino',
  'ZCOOL KuaiLe',
  'ZCOOL QingKe HuangYou',
  'ZCOOL XiaoWei',
  'Zeyada',
  'Zhi Mang Xing',
  'Zilla Slab',
  'Zilla Slab Highlight',
  'Zurich BlkEx BT',
  'Zurich Ex BT',
  'ZWAdobeF',
  'מרים',
  'মিত্র',
  'মুক্তি',
  'মুক্তি পাতনা',
  'गार्गी',
  'नालिमाटी',
  'অনি Dvf',
  '宋体',
  '微软雅黑',
  '游ゴシック',
  '細明體',
  '細明體_HKSCS',
  '新細明體',
  '굴림',
  '굴림체',
  '바탕',
  'ＭＳ ゴシック',
  'ＭＳ 明朝',
  'ＭＳ Ｐゴシック',
  'ＭＳ Ｐ明朝'
]

// 获取检测到的字体列表
export function getEnabledFontList() {
  const fonts = measureFonts()

  const metricsBox = document.querySelector('#font-metrics-box')
  if (metricsBox) {
    document.body.removeChild(metricsBox)
  }
  return fonts.join()
}

// 测量字体宽高信息
function getFontMetrics(element, fontFamily) {
  element.style.fontFamily = fontFamily
  return `${element.offsetWidth},${element.offsetHeight}`
}

// 初始化测试容器
function createTestContainer() {
  const container = document.createElement('div')
  container.id = 'font-metrics-box'
  container.style.position = 'absolute'
  container.style.visibility = 'hidden'
  document.body.appendChild(container)
  return container
}

// 测试字体度量信息
function measureFonts() {
  const baseFontFamilies = ['default', 'sans-serif', 'serif', 'monospace', 'cursive', 'fantasy']

  const testContainer = createTestContainer()
  const testSpan = document.createElement('span')
  testSpan.style.fontSize = '128px'
  testSpan.textContent = 'mmmMMMmmmlllmmmLLL₹▁₺₸ẞॿmmmiiimmmIIImmmwwwmmmWWW'
  testContainer.appendChild(testSpan)

  const metricsMap = {}
  const defaultMetrics = getFontMetrics(testSpan, 'default')

  // 测试基本字体族
  baseFontFamilies.forEach(font => {
    const metrics = getFontMetrics(testSpan, font)
    metricsMap[metrics] = (metricsMap[metrics] || []).concat(font)
  })

  // 测试字体列表
  ALL_FONTS.forEach(font => {
    const metrics = getFontMetrics(testSpan, font)
    if (metrics !== defaultMetrics) {
      metricsMap[metrics] = (metricsMap[metrics] || []).concat(font)
    }
  })

  return Array.from(Object.values(metricsMap)).reduce((acc, cur) => {
    return acc.concat(cur)
  }, [])
}

export const FONT_FAMILY_LIST = [
  'Comic Sans MS Bold',
  'Comic Sans',
  'Caurier Regular',
  'Fixedsys Regular',
  'Gabriola Regular',
  'HoloLens MDL2 Assets Regular',
  'Impact Regular',
  'Javanese Text Regular',
  'Leelawadee UI',
  'Lucida Console Regular',
  'Lucida Sans Unicode Regular',
  'Microsoft Himalaya Regular',
  'Microsoft PhangsPa',
  'Microsoft Sans Serif Regular',
  'Microsoft YaHei UI',
  'Microsoft Yi Baiti Regular',
  'MingLiu_HKSCS-ExtB Regular',
  'MingLiu-ExtB Regular',
  'Modern Regular',
  'Mongolia Baiti Regular',
  'MS Gothic Regular',
  'MS PGothic Regular',
  'MS Sans Serif Regular',
  'MS Serif Regular',
  'MS UI Gothic Regular',
  'MV Boli Regular',
  'Nimarla UI',
  'Myanmar Tet',
  'Nirmala UI',
  'NSimSun Regular',
  'PMingLiU-ExtB Regular',
  'Roman Regular',
  'Script Regular',
  'Segoe MDL2 Assets Regular',
  'Segoe UI Emoji Regular',
  'Segoe UI Historic Regular',
  'Segoe UI Symbol Regular',
  'SimSun Regular',
  'SimSun-ExtB Regular',
  'Sitka Banner',
  'Sitka Display',
  'Sitka Heading',
  'Sitka Subheading',
  'Sitka Text',
  'Small Fonts Regular',
  'Sylfaen Regular',
  'Symbol Regular',
  'System Bold',
  'Terminal',
  'Webdings Regular',
  'Wingdings Regular',
  'Yu Gothic UI',
  'Calibri Light',
  'Courier',
  'Fixedsys',
  'Franklin Gothic Medium',
  'HoloLens MDL2 Assets',
  'Leelawadee UI Semilight',
  'MS Sans Serif',
  'MS Serif',
  'Malgun Gothic Semilight',
  'Microsoft JhengHei Light',
  'Microsoft JhengHei UI Light',
  'Microsoft YaHei Light',
  'Microsoft YaHei UI Light',
  'Modern',
  'NSimsun',
  'Nirmala UI Semilight',
  'Roman',
  'Script',
  'Segoe MDL2 Assets',
  'Segoe UI Black',
  'Segoe UI Historic',
  'Segoe UI Light',
  'Segoe UI Semibold',
  'Segoe UI Semilight',
  'Small Fonts',
  'System',
  'Wingdings',
  'Yu Gothic Light',
  'Yu Gothic Medium',
  'Yu Gothic UI Light',
  'Yu Gothic UI Semibold',
  'Yu Gothic UI Semilight',
  'Al Bayan',
  'Al Nile',
  'Al Tarikh',
  'American Typewriter',
  'Andale Mono',
  'Apple Braille',
  'Apple Chancery',
  'Apple Color Emoji',
  'Apple SD Gothic Neo',
  'Apple Symbols',
  'AppleGothic',
  'AppleMyungjo',
  'Avenir Next Condensed',
  'Avenir Next',
  'Avenir',
  'Ayuthaya',
  'Baghdad',
  'Bangla MN',
  'Bangla Sangam MN',
  'Baskerville',
  'Beirut',
  'Big Caslon',
  'Bodoni Ornaments',
  'Bradley Hand',
  'Brush Script MT',
  'Chalkboard SE',
  'Chalkboard',
  'Chalkduster',
  'Cochin',
  'Copperplate',
  'Corsiva Hebrew',
  'Damascus',
  'DecoType Naskh',
  'Devanagari MT',
  'Devanagari Sangam MN',
  'Didot',
  'Diwan Kufi',
  'Diwan Thuluth',
  'Euphemia UCAS',
  'Farah',
  'Farisi',
  'Futura',
  'GB18030 Bitmap',
  'Geeza Pro',
  'Geneva',
  'Gill Sans',
  'Gujarati MT',
  'Gujarati Sangam MN',
  'Gurmukhi MN',
  'Gurmukhi MT',
  'Gurmukhi Sangam MN',
  'Heiti Sc',
  'Helvetica',
  'Herculanum',
  'Hiragino Sans GB',
  'Hiragino Sans',
  'Hoefler Text',
  'ITF Devanagari',
  'InaiMathi',
  'Kannada MN',
  'Kefa',
  'Khmer MN',
  'Khmer Sangam MN',
  'Kohinoor Bangla',
  'Kohinoor Telugu',
  'Kokonor',
  'Krungthep',
  'KufiStandardGK',
  'Lao MN',
  'Lao Sangam MN',
  'Lucida Grande',
  'Marker Felt',
  'Menlo',
  'Mishafi Gold',
  'Monaco',
  'Mshtakan',
  'Muna',
  'Nadeem',
  'New Peninim MT',
  'Noteworthy',
  'Optima',
  'Oriya Sangam MN',
  'PT Mono',
  'PT Sans Caption',
  'PT Sans Narrow',
  'PT Sans',
  'PT Serif Caption',
  'PT Serif',
  'Palatino',
  'Papyrus',
  'Phosphate',
  'PingFang HK',
  'Plantagenet Cherokee',
  'Raanana',
  'STIXGeneral',
  'STIXIntegralsD',
  'STIXIntegralsSm',
  'STIXIntegralsUp',
  'STIXIntegralsUpD',
  'STIXIntegralsUpSm',
  'STIXSizeFiveSym',
  'STIXSizeFourSym',
  'STIXSizeOneSym',
  'STIXSizeThreeSym',
  'STIXSizeTwoSym',
  'STIXVariants',
  'STSong',
  'Sana',
  'Sathu',
  'Savoye LET',
  'SignPainter',
  'Silom',
  'sinhala Sangam MN',
  'Skia',
  'Snell Roundhand',
  'Songti SC',
  'Sukhumvit Set',
  'Tamil Sangam MN',
  'Telugu Sangam MN',
  'Thonburi',
  'Trattatello',
  'Waseem',
  'Zapfino',
  'Charter',
  'DIN Alternate',
  'DIN Condensed',
  'Noto Nastaliq Urdu',
  'Rockwell',
  'Zapf Dingbats',
  'BlinkMacSystemFont',
  'Mishafi',
  'Myanmar MN',
  'Myanmar Sangam MN',
  'Oriya MN',
  'Songti TC',
  'Tamil MN',
  'Telugu MN'
]
