import { request } from 'boot/axios'
import { encryptData } from 'src/utils/crypto'
import { DeleteCacheMap } from 'utils/cache'

// 二进制请求
function streamRequest({ url, stream }) {
  return request({
    url,
    method: 'post',
    data: stream,
    headers: {
      // 'Content-Type': 'application/octet-stream',
      'X-Auth-Type': 'X1'
    }
  })
}

// 登录接口
export function login(params) {
  const binaryParams = encryptData(JSON.stringify(params))

  return streamRequest({ url: '/users/loginV2', stream: binaryParams })
}

export function emailLogin(params) {
  const binaryParams = encryptData(JSON.stringify(params))

  return streamRequest({ url: '/users/mailLoginV2', stream: binaryParams })
}

// 检测是否要弹滑块验证
export function getRestrictionStatus(params) {
  return streamRequest({ url: '/users/getRestrictionStatusV2', stream: encryptData(JSON.stringify(params)) })
}

// 注册
export async function register(data) {
  const binaryData = encryptData(JSON.stringify(data))
  return streamRequest({ url: '/users/registerV2', stream: binaryData })
}

// 注册发送短信验证码
export function sendSmsForRegister(data) {
  return streamRequest({ url: '/users/sendSmsForRegister', stream: encryptData(JSON.stringify(data)) })
}
// 验证是否是虚拟号码
export function checkIfVistualTel(data) {
  return streamRequest({ url: '/users/virtualPhone', stream: encryptData(JSON.stringify(data)) })
}

// 重置密码
export async function modifyPwd(data) {
  return streamRequest({ url: '/users/modifyPwd', stream: encryptData(JSON.stringify(data)) })
}

// 超过3个月重置密码
export async function personalCenterPwd(data) {
  return streamRequest({ url: '/users/personalCenterPwd', stream: encryptData(JSON.stringify(data)) })
}

// 重置密码获取验证码
export function sendSmsForResetPwd(data) {
  return streamRequest({ url: '/users/sendSmsForResetPwdV2', stream: encryptData(JSON.stringify(data)) })
}

// 用户列表
export function getUserList(data) {
  return request({ url: '/v1/sysUsers/list', method: 'post', data })
}

// 无分页子账号列表
export function getChildUserListNp() {
  return request({ url: '/v1/sysUsers/getChildUseid', method: 'post', cacheTime: 24, cacheAlias: DeleteCacheMap.ChildUserID })
}

// 用户停用
export function disactiveUser(data) {
  return request({ url: '/v1/sysUsers/updateStatusStop', method: 'post', data })
}

// 用户启用
export function recoverUser(data) {
  return request({ url: '/v1/sysUsers/recover', method: 'post', data })
}

// 员工管理-修改用户密码
export function updateUserPwd(data) {
  return request({ url: '/v1/sysUsers/updateUserPwd', method: 'post', data: data })
}

// 添加用户
export function addUser(data) {
  return request({ url: '/v1/sysUsers', method: 'post', data })
}

// 修改用户
export function editUser(data) {
  return request({ url: '/v1/sysUsers', method: 'put', data: data })
}

//删除用户
export function deleteUser(ids) {
  return request({ url: '/v1/sysUsers', method: 'delete', data: ids })
}

// 用户详情
export function getUserDetail(id) {
  return request({ url: '/v1/sysUsers/' + id, method: 'get' })
}

// 当前登录用户信息
export function getUserInfo() {
  return request({ url: '/users/currentUser', method: 'get' })
}

// 个人中心修改email and qq
export function updateCurrentUserInfo(data) {
  return request({ url: '/v1/sysUsers', method: 'put', data: data })
}
// 检测邮箱是否可以认证
export function checkEmail(email) {
  return streamRequest({ url: '/users/checkEmailV2', stream: encryptData(JSON.stringify({ email })) })
}
// 个人中心绑定email
export function bindEmail(data) {
  return streamRequest({ url: '/users/bindEmail', stream: encryptData(JSON.stringify(data)) })
}

// 个人中心修改密码
export function resetCurrentUserPwd(data) {
  return streamRequest({ url: '/users/resetPwd', stream: encryptData(JSON.stringify(data)) })
}

// 个人中心修改手机号
export function updateCurrentUserPhone(data) {
  return streamRequest({ url: '/users/modifyPhone', stream: encryptData(JSON.stringify(data)) })
}

// 个人中心修改手机号获取验证码
export function sendSms(data) {
  return streamRequest({ url: '/users/sendSmsModfyPhoneV2', stream: encryptData(JSON.stringify(data)) })
}

// checkPhone  修改手机号时验证此手机号是否绑定其他用户
export function checkPhoneOfUser(phone) {
  return streamRequest({ url: `/users/checkPhoneV2`, stream: encryptData(JSON.stringify({ phone })) })
}

// 退出登录
export function userLogout() {
  return request({ url: '/users/logout', method: 'get' })
}

// 打开
export function openRequestUrl(url) {
  return request({
    url: url,
    method: 'get'
  })
}

// 心跳
export function heartbeatFn(data, headers) {
  return request({ url: '/v1/sysUsers/softHeartBeat', method: 'post', data, headers })
}

// OEM信息
export function getOemInfo(data) {
  return request({ url: 'users/getOemInfo', method: 'post', data })
}

// 系统通知
export function getExpiredTips() {
  return request({ url: '/v1/softmessage', method: 'post', data: {} })
}

// 获取官方群二维码
export function getContactGroupQr() {
  return request({ url: '/users/getSoftGroup', method: 'post' })
}

// 找回用户名
export function getUserNameByPhone(data) {
  return streamRequest({ url: '/users/getUserNameByPhone', stream: encryptData(JSON.stringify(data)) })
}
// 确认密码
export function checkUserPass(data) {
  return request({ url: '/v1/sysUsers/checkUserPass', method: 'post', data })
}

// 找回用户名发送验证码
export function sendSmsForUsername(data) {
  return streamRequest({ url: '/users/sendSmsForUsernameV2', stream: encryptData(JSON.stringify(data)) })
}

// 获取客服二维码
export function getKefuImg() {
  return request({ url: '/users/getSoftKefu', method: 'post' })
}

// 手动退出软件
export function appQuitManually() {
  return request({ url: '/users/closeSoft', method: 'get' })
}

// 通知
export function getNotifys(data) {
  return request({ url: '/v1/sysNotice/list', method: 'post', data })
}
// 搜集用户退出错误
export function getUserApiErrorLog() {
  return request({ url: '/v1/sysUsers/getUserApiErrorLog', method: 'get' })
}
// 已知晓退出错误
export function clearApiErrorLog() {
  return request({ url: '/v1/sysUsers/clearApiErrorLog', method: 'get' })
}

export function getTopNotice() {
  return request({ url: '/v1/sysNotice/getTop', method: 'get' })
}

// 领取8折优惠券
export function getPromotionCouponForUser() {
  return request({ url: '/v1/sysUserCoupon/promotionCoupon', method: 'post' })
}

// 设置语言
export function setLanguage(lang) {
  return request({ url: '/v1/sysUsers/setLanguage', method: 'post', data: lang })
}

// 获取版本信息
export function getReleaseNotes(version) {
  return request({ url: '/v1/sysVersions/getContent', method: 'post', data: version })
}

// 自动续费
export function switchAutoRenew(data) {
  return request({ url: '/v1/sysUsers/autoRenew', method: 'post', data })
}

// 支付通道列表
export function getPaymentChannelList() {
  return request({
    url: '/v1/sysPayChannel/list',
    method: 'post',
    data: {}
  })
}

// 开启/关闭双重验证
export function switch2faStatus(data) {
  return request({ url: '/v1/sysUsers/setSecendAuthStatus', method: 'post', data })
}

// google 验证器密钥
export function get2faSecretKey() {
  return request({ url: '/v1/sysUsers/getGoogleAuthenticator', method: 'post' })
}

// 绑定Google验证器
export function bindAuthenticator(data) {
  return request({ url: '/v1/sysUsers/setSecondAuthenticator', method: 'post', data })
}

// 解绑Google验证器
export function unbindAuthenticator(data) {
  return request({ url: '/v1/sysUsers/closeSecondAuthenticator', method: 'post', data })
}

// 双重验证发送短信
export function send2faSms(data) {
  return streamRequest({ url: '/v1/sysUsers/sendSmsSecondAuthV2', stream: encryptData(JSON.stringify(data)) })
}

// 登录时双重验证发短信
export function send2faSmsOnLogin(data) {
  return streamRequest({ url: '/v1/sysUsers/loginSecondAuthBySmsV2', stream: encryptData(JSON.stringify(data)) })
}
//设置是否展示日志
export function updateViewLog(data) {
  return request({ url: '/v1/sysUsers/updateViewLog', method: 'post', data })
}
// 通过Email发送验证码
export function getCodeFromEMail(obj) {
  return streamRequest({ url: '/users/getCodeFromEMailV2', stream: encryptData(JSON.stringify(obj)) })
}
// 是否开启彻底删除窗口密码验证
export function isCheckPwd(data) {
  return request({ url: '/v1/sysUsers/isCheckPwd', method: 'post', data })
}
// 是否开启转移窗口密码验证
export function isDivertPwd(data) {
  return request({ url: '/v1/sysUsers/isDivertPwd', method: 'post', data })
}

// 获取系统时间，无鉴权
export function getServerTime() {
  return request({ url: '/v1/apis/getServiceTimes', method: 'get' })
}

// 收集系统信息，无鉴权
export function dataCollectionAndUpload(data) {
  return streamRequest({ url: '/v1/apis/setFingerPrintData', stream: encryptData(JSON.stringify(data)) })
}

// 获取滑块验证码
export function getVerification(data) {
  return streamRequest({ url: '/users/getVerificationV2', stream: encryptData(JSON.stringify(data)) })
}
// 校验登陆滑块验证码
export function checkVerificationImg(data) {
  return streamRequest({ url: '/users/checkVerificationImgV2', stream: encryptData(JSON.stringify(data)) })
}
// 获取登陆验证码
export function getValidationImg(data) {
  return streamRequest({ url: '/users/getValidationImg', stream: encryptData(JSON.stringify(data)) })
}

// 校验滑块验证码
export function checkVerificationImage(data) {
  return streamRequest({ url: '/users/checkVerificationImageV2', stream: encryptData(JSON.stringify(data)) })
}
// 列表开启关闭二次验证
export function listSetSecendAuthStatus(data) {
  return request({ url: '/v1/sysUsers/listSetSecendAuthStatus', data, method: 'post' })
}
// 币种列表
export function getSymbolList() {
  return request({
    url: '/v1/sysPackages/currencyList',
    method: 'GET'
  })
}

// 启用/关闭 Local Token 1/0
export function switchLocalApiAuth(status) {
  return request({ url: `/v1/sysUsers/setIsLocalToken/${status}`, method: 'post' })
}

// 重置Local Auth Token
export function resetLocalAuthToken() {
  return request({ url: '/v1/sysUsers/resetLocalToken', method: 'post' })
}

// 重新生成用户token
export function generateNewUserToken() {
  return request({ url: '/v1/sysUsers/generateNewToken', method: 'post' })
}
// 实名认证，获取支付宝二维码地址
export function getRealnameAuthUrl(data) {
  return request({ url: '/v1/sysUserAuthentications/authUser', method: 'post', data })
}

// 获取企业认证状态
export function getCertificationStatus() {
  return request({ url: '/v1/sysUserAuthentications/getCertificationStatus', method: 'get' })
}

// 验证实名状态
export function getAuthUserStatus(params) {
  return request({ url: '/v1/sysUserAuthentications/getAuthUserStatus', method: 'get', params })
}

// 查询用户实名认证状态
export function checkRealnameAuth() {
  return request({ url: '/v1/sysUserAuthentications/getAuthUser', method: 'get' })
}

// 企业认证发送短信
export function sendMsmToLegalPerson(data) {
  return request({ url: '/v1/sysUserAuthentications/sendMsmToLegalPerson', method: 'post', data })
}

// 查询反向打款进度接口
export function queryReversePaymentSchedule() {
  return request({ url: '/v1/sysUserAuthentications/queryReversePaymentSchedule', method: 'get' })
}

// 获取签署状态接口
export function getAuthorizationSigningStatus() {
  return request({ url: '/v1/sysUserAuthentications/getAuthorizationSigningStatus', method: 'get' })
}

// 发起授权签署并获取签署链接接口
export function initiateAuthorizationSigning(data) {
  return request({ url: '/v1/sysUserAuthentications/initiateAuthorizationSigning', method: 'post', data })
}

// 获取企业对公打款账户信息
export function initiateReversePayment() {
  return request({ url: '/v1/sysUserAuthentications/initiateReversePayment', method: 'get' })
}

// 企业四要素核身认证接口
export function enterpriseCertification4(data) {
  return request({ url: '/v1/sysUserAuthentications/enterpriseCertification4', method: 'post', data })
}

// 查询经办人认证状态
export function getCompanyCertificationInfo() {
  return request({ url: '/v1/sysUserAuthentications/getCompanyCertificationInfo', method: 'get' })
}
// 6个月没改密码，让后端记录时间
export function updatePasswordTime() {
  return request({ url: '/users/passwordModificationTime', method: 'post' })
}
