export default {
  seq: 'Номер: ',
  copyID: 'Копировать ID',
  baseInfo: 'Базовые настройки',
  proxyInfo: 'Настройки прокси',
  preferenceInfo: 'Общие настройки',
  fingerprintInfo: 'Настройки отпечатков',
  clonePlaceholder: 'Введите количество клонов',
  batchAddPlaceholder: 'Введите количество пакетного создания',
  numberOfClones: 'Количество клонов',
  numberOfAdd: 'Количество созданий',
  cloneNumTips: 'Количество клонов этого окна',
  batchAddNumTips: 'Пакетное создание указанного количества окон',
  genFingerprintType: 'Способ создания отпечатка',
  randomFingerprint: 'Случайный отпечаток',
  cloneFingerprint: 'Клонировать отпечаток',
  randomFpTips: 'Будет сгенерирован случайный UA и параметры отпечатка',
  cloneFpTips: 'Будут скопированы UA текущего окна и параметры отпечатка',
  namePlaceholder: 'Название пользовательского окна браузера',
  namePrefix: 'Префикс названия окна',
  browserName: 'Название',
  batchAddNameTips:
    'Назовите это окно браузера. Имя окна будет использоваться как префикс, совмещаясь с номером окна, чтобы сформировать окончательное имя окна. Например: пакетное создание окон_1234',
  addNameTips: 'Назовите это окно браузера, чтобы облегчить повседневное управление!',
  group: 'Группировка окон',
  groupSelect: 'Выберите группу',
  groupPlaceholder: 'Выберите группу',
  groupSelectTips:
    'Примечание: если выбрана группа из другого аккаунта, окно будет перемещено в группу другого аккаунта (собственность окна будет принадлежать другому аккаунту).',
  noGroup: 'Без группы',
  duplicateValidation: 'Проверка наличия дубликатов',
  validByUsername: 'Проверить по имени пользователя',
  validByUsernameTips:
    'Проверка наличия дубликатов производится только если платформа и имя пользователя не являются пустыми. Если оба параметра совпадают со всеми предыдущими данными, то окно будет считаться дубликатом и не будет создано!',
  usernamePlaceholder: 'Укажите имя пользователя для входа на платформу',
  platform: 'Платформа аккаунта',
  platformAuth: 'Аккаунт',
  username: 'Имя пользователя',
  usernameTips: 'После настройки, при открытии окна браузера имя пользователя для указанной платформы будет автоматически заполняться!',
  passwordPlaceholder: 'Установите пароль для входа на платформу',
  password: 'Пароль',
  passwordTips: 'После настройки, при открытии окна браузера пароль для указанной платформы будет автоматически заполняться!',
  remarkPlaceholder: 'Введите комментарий для окна браузера',
  remark: 'Заметка',
  cookiePlaceholder: 'Опционально, поддерживает массив, содержащий JSON-формат cookie, например: {demo}',
  paste: 'Вставить',
  cookieTips: 'Введите правильный формат cookie',
  parseCookies: 'Ремонт',
  otherCookies: 'Другие cookie',
  addOtherCookies: 'Добавить другие cookie',
  otherCookiesPlaceholder: 'Другие cookie, можно добавлять по частям',
  add: 'Добавить',
  randomAll: 'Случайная генерация конфигурации отпечатка',
  addRuleMsg: 'Можно одновременно создать от 1 до 1000 окон',
  nameRuleMsg: 'Имя окна должно содержать не более 50 символов',
  groupRuleMsg: 'Выберите группу',
  remarkRuleMsg: 'Комментарий должен содержать не более 500 символов',
  usernameRuleMsg: 'Имя пользователя должно содержать не более 100 символов',
  passwordRuleMsg: 'Пароль должен содержать не более 100 символов',
  toGroupConfirm: 'Вы уверены, что хотите перейти к групповому контролю?<br/>После перехода введенная информация будет потеряна.',
  editSuccess: 'Успешно изменено',
  addSuccess: 'Успешно добавлено',
  validationErrMsg: ' Ошибка проверки',
  pasteError: 'Ошибка при вставке, пожалуйста, введите вручную',
  parseCookiesFailed: 'Не удалось исправить',
  copySuccess: 'Успешно скопировано',
  coreVersion: 'Версия ядра браузера',
  coreVersionTips:
    'Рекомендуется выбрать самую новую версию ядра. Если вы меняете ядро на другое, пожалуйста, очистите кэш, чтобы избежать ошибок.',
  ostype: 'Устройство',
  pcTips: 'Эмулировать в окне браузера среду ПК и генерировать User-Agent для ПК!',
  androidTips: 'Эмулировать в окне браузера среду мобильного телефона Android и генерировать User-Agent для Android!',
  iosTips: 'Эмулировать в окне браузера среду мобильного телефона iPhone и генерировать User-Agent для iPhone!',
  osPlaceholder: 'Выберите операционную систему',
  os: 'Операционная система',
  osTips: 'Значение navigator platform',
  versionPlaceholder: 'Выберите версию браузера',
  version: 'Версия браузера',
  uaPlaceholder: 'Рекомендуется автоматическое создание',
  changeBtn: 'Поменять',
  language: 'Язык',
  languageTips: 'Язык браузера соответствует стране, сгенерированной IP. При выключении можно настроить вручную.',
  checkLanguage: 'Выбрать язык',
  displayLanguage: 'Язык интерфейса',
  displayLanguageTips:
    'Язык интерфейса браузера основан на стране, сгенерированной IP. Пользовательская настройка доступна при выключении.',
  checkDisplayLanguage: 'Выбрать язык интерфейса',
  displayLanguageValueTips: 'По умолчанию вслед за системой',
  timezone: 'Часовой пояс',
  timezoneTips: 'На основании IP генерируется соответсвующую часовую зону. Настройка доступна при отключении',
  checkTimezone: 'Выбрать часовой пояс',
  webrtcTips0: 'Включить WebRTC, заменить IP общей сети  на проски-IP и скрыть локальный IP',
  webrtcTips1: 'Включить WebRTC, использовать реальный IP текущего компьютера',
  webrtcTips2: 'WebRTC отключен, веб-сайт определит, что у вас отключен WebRTC',
  ignoreHttps: 'Игнорировать сертификаты HTTPS',
  ignoreHttpsTips: 'Открывать веб-страницу, игнорируя ошибки HTTPS-сертификата',
  position: 'Локация',
  positionTips0:
    'Сайт может запрашивать доступ к вашему текущему местоположению, подобно обычному запросу в обозревателе, можно разрешить или запретить',
  positionTips1: 'При запросе сайтом вашего текущего местоположения, всегда разрешено',
  positionTips2: 'При запросе сайтом вашего текущего местоположения, всегда запрещено',
  positionValueTips:
    'На основании IP генерируется соответствующее географическое положение. Пользовательская настройка доступна при отключении.',
  lngPlaceholder: 'Введите долготу',
  lat: 'Широта',
  precisionPlaceholder: 'Введите точность',
  precision: 'Точность (метры)',
  windowSize: 'Размер окна',
  openWidth: 'Ширина окна',
  openHeight: 'Высота окна',
  windowSizeTips: 'Установите размер окна браузера при открытии',
  resolution: 'Разрешение',
  windowSizeLimit: 'Максимальный размер окна ограничен разрешением',
  pixelRatio: 'Масштаб экрана',
  fonts: 'Шрифты',
  edit: 'Редактировать',
  canvasTips0: 'Разные Canvas для каждого браузера на одном компьютере',
  canvasTips1: 'Каждый браузер использует стандартный Canvas текущего компьютера',
  webgl: 'WebGL изображение',
  webglTips0: 'Разные WebGL для каждого браузера на одном компьютере',
  webglTips1: 'Каждый браузер использует стандартный WebGL текущего компьютера',
  webglMeta: 'Метаданные WebGL',
  webglVender: 'Производитель WebGL',
  webglRender: 'Рендерер WebGL',
  audioTips0: 'Разные Audio для каждого браузера на одном компьютере',
  audioTips1: 'Каждый браузер использует стандартный Audio текущего компьютера',
  mediaDevice: 'Мультимедийное устройство',
  mediaDeviceTips0: 'Используйте соответствующие значения вместо реальных идентификаторов мультимедийных устройств',
  mediaDeviceTips1: 'Каждый браузер использует стандартные идентификаторы мультимедийных устройств текущего компьютера',
  speechTips0: 'Используйте соответствующие значения вместо реальных Speech Voices',
  speechTips1: 'Каждый браузер использует стандартные Speech Voices текущего компьютера',
  doNotTrackTips: 'Установите, желаете ли вы, чтобы браузер отслеживал вашу персональную информацию на сайтах',
  clientRectTips0: 'Используйте соответствующие значения вместо реальных ClientRects',
  clientRectTips1: 'Каждый браузер использует стандартные ClientRects текущего компьютера',
  deviceInfo: 'Информация об устройстве',
  deviceInfoTips0: 'Используйте соответствующие значения вместо реального названия устройства и MAC-адреса',
  deviceInfoTips1: 'Каждый браузер использует стандартное название устройства и MAC-адрес текущего компьютера',
  computerNamePlaceholder: 'Пользовательское название устройства',
  computerName: 'Название устройства',
  macAddrPlaceholder: 'Введите MAC-адрес',
  macAddr: 'MAC-адрес',
  portScan: 'Защита от сканирования портов',
  portScanTips: 'При включении будет блокироваться сканирование локальных портов сайтами',
  portWhiteList: 'Список разрешённых портов',
  portWhiteListTips: 'Разрешённые для сканирования сайтами порты, перечислите через запятую',
  portWhiteListPlaceholder: 'Введите порт целым числомили через запятую, если их несколько',
  ssl: 'Настройки отпечатков SSL',
  sslTips: 'Включение может блокировать некоторые сайты из-за отключенных SSL функций',
  disableSSL: 'Отключить функции SSL',
  plugins: 'Отпечаток пользовательского плагина',
  pluginsTips: 'При включении пользовательские плагины могут влиять на реальное функционирование плагинов',
  pluginsInfo: 'Информация о плагинах',
  pluginsDetails: 'Пользовательские плагины ({num})',
  cpu: 'Параллельные процессы аппаратного обеспечения',
  cpuUnit: 'Ядра',
  cpuTips: 'Установите количество ядер CPU для текущей среды браузера',
  deviceMemory: 'Объем памяти устройства',
  deviceMemoryTips: 'Установите объем памяти устройства для текущей среды браузера',
  customizeFonts: 'Настроить шрифты',
  fontPlaceholder: 'Введите название шрифта для поиска',
  customizePlugins: 'Настроить плагины',
  addPlugin: 'Добавить плагин',
  editPlugin: 'Изменить плагин',
  reset2Default: 'Сбросить к настройкам по умолчанию',
  pluginName: 'Название плагина',
  pluginDesc: 'Описание',
  pluginFilename: 'Имя файла',
  pluginMime: 'Описание MIME',
  pluginSuffixes: 'MIME типы',
  lngRuleMsg: 'Введите значение долготы, с максимум 6 знаками после запятой',
  latRuleMsg: 'Введите значение широты, с максимум 6 знаками после запятой',
  precisionRuleMsg: 'Введите значение точности в диапазоне от 3 до 5000 метров',
  languageRuleMsg: 'Выберите язык',
  timezoneRuleMsg: 'часовую зону',
  portWhiteListRuleMsg: 'Введите корректные порты',
  sslRuleMsg: 'Выберите отключаемые функции ',
  pluginNameRuleMsg: 'Введите название плагина',
  pluginDescRuleMsg: 'Введите описание плагина',
  pluginFilenameRuleMsg: 'Введите имя файла',
  pluginMimeRuleMsg: 'Введите MIME тип',
  pluginMimeDescRuleMsg: 'Введите описание MIME',
  pluginSuffixesRuleMsg: 'Введите расширения MIME',
  setSuccess: 'Настройки отпечатка сформированы успешно',
  switchCoreVersionMsg:
    'Внимание: Если после переключения ядра браузера возникнут аномальные функции окна браузера, пожалуйста, попробуйте очистить кэш этого окна браузера перед повторной попыткой!',
  clearCacheAndSave: 'Очистить кэш и сохранить',
  cancelSwitchAndSave: 'Отменить переключение и сохранить',
  batchUpdateConfig: 'Пакетное обновление конфигурации окна',
  batchUpdateTips:
    'Если вы хотите изменить какой-либо параметр, установите флажок слева от этого параметра, а затем измените его значение. Все окна будут иметь одинаковое значение этого параметра!',
  enable: 'Включить',
  disable: 'Выключить',
  replace: 'Заменить',
  allow: 'Разрешить',
  forbidden: 'Запретить',
  default: 'По умолчанию',
  customize: 'Настроить',
  random: 'Случайный выбор',
  randomValue: 'Случайное значение',
  followComputer: 'Следовать за компьютером',
  ask: 'Спросить',
  multipleUrlPlaceholder:
    '1. Префикс URL: http:// или https:// или chrome-extension://;\n2. Введите несколько строк URL-адресов, по одному URL-адресу в строке;\n3. После ввода URL-адреса нажмите кнопку "+" справа.',
  urlPlaceholder: 'Префикс URL: http:// или https:// или chrome-extension://',
  otherUrl: 'Открыть указанный URL',
  urlTips:
    'Здесь вы можете указать URL, который будет открыт при запуске окна браузера. Если не указан, будет открыт только адрес платформы аккаунта!',
  urlRule1: 'Длина URL не должна превышать 1024 символа',
  urlRule2: 'Введите правильный URL',
  urlRule3: 'URL уже существует',
  urlRule4: 'Введите URL перед добавлением',
  urlRule5: 'Превышено максимальное количество указанных URL. Добавление новых URL запрещено!',
  platformPlaceholder: 'Выберите платформу аккаунта, пользовательские настройки доступны в "Другие платформы" ниже',
  platformOthers: 'Другие платформы',
  platformOtherTips:
    'Выберите платформу аккаунта или выберите "Другие платформы" для настройки URL. При каждом запуске окна браузера будет открыт URL платформы аккаунта, а также автоматически заполнены имя пользователя и пароль на странице входа в эту платформу.',
  platformUrl: 'URL платформы',
  platformUrlPlaceholder: 'Добавьте "http://" или "https://" перед URL. Оставьте пустым, если ничего не нужно открывать!',
  platformRule1: 'Выберите платформу аккаунта',
  platformRule2: 'Длина URL платформы не должна превышать 100 символов',
  platformRule3: 'Введите правильный URL',
  cookieBackup: 'Резервирование',
  cookieRestore: 'Восстановление',
  backupTips: 'Резервировать текущие значения Cookie на сервере',
  restoreTips: 'Восстановить ранее сохраненные значения Cookie с сервера',
  lastBackupTime: 'Последнее резервное копирование:',
  trimName: 'Пожалуйста, удалите начальные и конечные пробелы в тексте',
  osVersion: 'Версия ОС',
  enterOsVersion: 'Введите версию ОС',
  editUrl: 'Изменить URL',
  editOtherUrl: 'Редактировать указанный URL',
  latPlaceholder: 'Введите широту',
  enterWebglVender: 'Введите производителя WebGL',
  enterWebglRender: 'Введите рендер WebGL',
  lng: 'Долгота',
  speechVoices: 'Speech Voices',
  AudioContext: 'AudioContext',
  doNotTrack: 'Do Not Track',
  clientRects: 'ClientRects',
  quickSettings: 'Быстрый выбор',
  resolutionValue: 'Значение разрешения',
  enterResolute: 'Введите разрешение',
  American: 'Американский сервер',
  Chinese: 'Китайский сервер',
  hkServer: 'Сервер Восточной Азии',
  defaultServer: ' Стандартный сервер',
  usServer: 'Сервер Американский ',
  seaServer: 'Сервер Юго-Восточной Азии',
  euServer: 'Европейский сервер',
  auServer: 'Сервер Австралии',
  inServer: 'Сервер Южной Азии',
  thServer: 'Сервер Таиланда',
  gbServer: 'Британский сервер ',
  jpServer: 'Сервер Японии',
  enServer: 'Европейский сервер',
  asServer: 'Азиатский сервер',
  Singaporean: 'Сингапурский сервер',
  multiOpen: 'Настройки многозадачности',
  allowMutiOpenTip: 'Разрешить нескольким учетным записям открывать одно и то же окно браузера одновременно',
  notAllopMutiOpenTip: 'Не разрешать нескольким учетным записям открывать одно и то же окно браузера одновременно',
  allowed: 'Разрешено',
  notAllowed: 'Запрещено',
  secretKey: 'Ключ 2FA',
  secretKeyPlaceholder: 'Введите ключ',
  secretKeyTip: 'Подходит для генерации вторичного пароля для веб-сайта, аналогичного Google Authenticator.',
  secretKeyRuleMsg1: 'Ключ состоит из цифр или букв',
  secretKeyRuleMsg2: 'Ключ может содержать до 100 символов',
  batchChangeUa1: 'Создание UA по конфигурации',
  selectBrowserUA: 'UA выбранного окна будет полностью заменен на этот UA',
  coreProduct: 'Браузер',
  isMacTips: 'Система Mac временно не поддерживает ядро Firefox. Выберите вместо него ядро Google.',
  batchUaTitle: 'Пакетное изменение UA',
  changeUaType: 'Способ генерации UA',
  stochasticUa: 'Случайная генерация UA',
  equalUa: 'Заменить на тот же UA',
  equalUaTip: 'UA выбранного окна будет заменен на указанный User Agent ниже',
  privacy: 'Конфиденциальность',
  privacyTips: 'Включить WebRTC, но запретить получение IP',
  launchArgsTips: 'Параметры запуска браузера, такие как --mute. Несколько параметров следует разделять запятыми.',
  selectComputingPower: 'Выбор мощности',
  intelligentComputing: 'Умное распределение мощности',
  timeComputing: 'Временные вычислительные мощности',
  subscriptionComputing: 'Ежемесячные вычислительные мощности',
  useComputing: 'Можно <span class="text-primary"> {num} </span> штуки',
  basicEnvironment: 'Базовые настройки',
  launchArgs: 'Параметры запуска',
  launchArgsError: 'Пожалуйста, введите правильные параметры запуска.',
  mac15ProhibitOpen118:
    'Из-за системных ограничений macOS 15 ядро версии 118 не может быть запущено. Пожалуйста, выберите другое ядро версии!'
}
