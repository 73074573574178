import { dataCollectionAndUpload } from 'src/api/user'
import { getHash } from './jssha'
import { getEnabledFontList } from 'src/static-data/font'
import { Cookies, uid } from 'quasar'

const WebGL_Parameters = [
  'ACTIVE_TEXTURE',
  'ALIASED_LINE_WIDTH_RANGE',
  'ALIASED_POINT_SIZE_RANGE',
  'ALPHA_BITS',
  'BLEND',
  'BLEND_COLOR',
  'BLEND_DST_ALPHA',
  'BLEND_DST_RGB',
  'BLEND_EQUATION',
  'BLEND_EQUATION_ALPHA',
  'BLEND_EQUATION_RGB',
  'BLEND_SRC_ALPHA',
  'BLEND_SRC_RGB',
  'BLUE_BITS',
  'COLOR_CLEAR_VALUE',
  'COLOR_WRITEMASK',
  'COMPRESSED_TEXTURE_FORMATS',
  'CULL_FACE',
  'CULL_FACE_MODE',
  'DEPTH_BITS',
  'DEPTH_CLEAR_VALUE',
  'DEPTH_FUNC',
  'DEPTH_RANGE',
  'DEPTH_TEST',
  'DEPTH_WRITEMASK',
  'DITHER',
  'FRONT_FACE',
  'GENERATE_MIPMAP_HINT',
  'GREEN_BITS',
  'IMPLEMENTATION_COLOR_READ_FORMAT',
  'IMPLEMENTATION_COLOR_READ_TYPE',
  'LINE_WIDTH',
  'MAX_COMBINED_TEXTURE_IMAGE_UNITS',
  'MAX_CUBE_MAP_TEXTURE_SIZE',
  'MAX_FRAGMENT_UNIFORM_VECTORS',
  'MAX_RENDERBUFFER_SIZE',
  'MAX_TEXTURE_IMAGE_UNITS',
  'MAX_TEXTURE_SIZE',
  'MAX_VARYING_VECTORS',
  'MAX_VERTEX_ATTRIBS',
  'MAX_VERTEX_TEXTURE_IMAGE_UNITS',
  'MAX_VERTEX_UNIFORM_VECTORS',
  'MAX_VIEWPORT_DIMS',
  'PACK_ALIGNMENT',
  'POLYGON_OFFSET_FACTOR',
  'POLYGON_OFFSET_FILL',
  'POLYGON_OFFSET_UNITS',
  'RED_BITS',
  'RENDERER',
  'SAMPLE_BUFFERS',
  'SAMPLE_COVERAGE_INVERT',
  'SAMPLE_COVERAGE_VALUE',
  'SAMPLES',
  'SCISSOR_BOX',
  'SCISSOR_TEST',
  'SHADING_LANGUAGE_VERSION',
  'STENCIL_BACK_FAIL',
  'STENCIL_BACK_FUNC',
  'STENCIL_BACK_PASS_DEPTH_FAIL',
  'STENCIL_BACK_PASS_DEPTH_PASS',
  'STENCIL_BACK_REF',
  'STENCIL_BACK_VALUE_MASK',
  'STENCIL_BACK_WRITEMASK',
  'STENCIL_BITS',
  'STENCIL_CLEAR_VALUE',
  'STENCIL_FAIL',
  'STENCIL_FUNC',
  'STENCIL_PASS_DEPTH_FAIL',
  'STENCIL_PASS_DEPTH_PASS',
  'STENCIL_REF',
  'STENCIL_TEST',
  'STENCIL_VALUE_MASK',
  'STENCIL_WRITEMASK',
  'SUBPIXEL_BITS',
  'UNPACK_ALIGNMENT',
  'UNPACK_COLORSPACE_CONVERSION_WEBGL',
  'UNPACK_FLIP_Y_WEBGL',
  'UNPACK_PREMULTIPLY_ALPHA_WEBGL',
  'VENDOR',
  'VERSION',
  'VIEWPORT'
]

const WebGL_Parameters2 = [
  'DRAW_BUFFERi',
  'FRAGMENT_SHADER_DERIVATIVE_HINT',
  'MAX_3D_TEXTURE_SIZE',
  'MAX_ARRAY_TEXTURE_LAYERS',
  'MAX_CLIENT_WAIT_TIMEOUT_WEBGL',
  'MAX_COLOR_ATTACHMENTS',
  'MAX_COMBINED_FRAGMENT_UNIFORM_COMPONENTS',
  'MAX_COMBINED_UNIFORM_BLOCKS',
  'MAX_COMBINED_VERTEX_UNIFORM_COMPONENTS',
  'MAX_DRAW_BUFFERS',
  'MAX_ELEMENT_INDEX',
  'MAX_ELEMENTS_INDICES',
  'MAX_ELEMENTS_VERTICES',
  'MAX_FRAGMENT_INPUT_COMPONENTS',
  'MAX_FRAGMENT_UNIFORM_BLOCKS',
  'MAX_FRAGMENT_UNIFORM_COMPONENTS',
  'MAX_PROGRAM_TEXEL_OFFSET',
  'MAX_SAMPLES',
  'MAX_SERVER_WAIT_TIMEOUT',
  'MAX_TEXTURE_LOD_BIAS',
  'MAX_TRANSFORM_FEEDBACK_INTERLEAVED_COMPONENTS',
  'MAX_TRANSFORM_FEEDBACK_SEPARATE_ATTRIBS',
  'MAX_TRANSFORM_FEEDBACK_SEPARATE_COMPONENTS',
  'MAX_UNIFORM_BLOCK_SIZE',
  'MAX_UNIFORM_BUFFER_BINDINGS',
  'MAX_VARYING_COMPONENTS',
  'MAX_VERTEX_OUTPUT_COMPONENTS',
  'MAX_VERTEX_UNIFORM_BLOCKS',
  'MAX_VERTEX_UNIFORM_COMPONENTS',
  'MIN_PROGRAM_TEXEL_OFFSET',
  'PACK_ROW_LENGTH',
  'PACK_SKIP_PIXELS',
  'PACK_SKIP_ROWS',
  'RASTERIZER_DISCARD',
  'READ_BUFFER',
  'SAMPLE_ALPHA_TO_COVERAGE',
  'SAMPLE_COVERAGE',
  'TRANSFORM_FEEDBACK_ACTIVE',
  'TRANSFORM_FEEDBACK_PAUSED',
  'UNIFORM_BUFFER_OFFSET_ALIGNMENT',
  'UNPACK_IMAGE_HEIGHT',
  'UNPACK_ROW_LENGTH',
  'UNPACK_SKIP_IMAGES',
  'UNPACK_SKIP_PIXELS',
  'UNPACK_SKIP_ROWS'
]

const Shader_PrecisionType = ['LOW_FLOAT', 'MEDIUM_FLOAT', 'HIGH_FLOAT', 'LOW_INT', 'MEDIUM_INT', 'HIGH_INT']

function getTypedArrayType(array) {
  if (array instanceof Int8Array) return 'Int8Array'
  if (array instanceof Uint8Array) return 'Uint8Array'
  if (array instanceof Uint8ClampedArray) return 'Uint8ClampedArray'
  if (array instanceof Int16Array) return 'Int16Array'
  if (array instanceof Uint16Array) return 'Uint16Array'
  if (array instanceof Int32Array) return 'Int32Array'
  if (array instanceof Uint32Array) return 'Uint32Array'
  if (array instanceof Float32Array) return 'Float32Array'
  if (array instanceof Float64Array) return 'Float64Array'
  return 'Unknown'
}

/*
 * @description 从url中匹配hostname
 * @params {string} url
 */
export function getHostname(url) {
  // 自定义域名可能没有以 / 结尾，手动补上，以匹配以下正则
  if (!url.endsWith('/')) {
    url += '/'
  }
  const domain = url.match(/^http(s)?:\/\/(www.)?(.*?)\//)
  if (domain) {
    return domain[3]
  }
  return url
}
export function getSymbolFlag(isZh, money, flag, moneyUnit) {
  money = money ?? 0
  if (flag === '¥') {
    if (moneyUnit) {
      if (isZh) {
        return money + moneyUnit
      } else {
        return moneyUnit + money
      }
    }
  }
  return flag + ' ' + money
}
// 获取网站地址
export function getDomain(language) {
  let domain = {
    zh: 'cn',
    en: 'net',
    ru: 'ru'
  }
  let lang = domain[language] || domain.en
  return `https://www.bitbrowser.${lang}`
}
// 跳转至官网下载页面，redirect 是否当前页面重定向
export function gotoDownload(redirect, language) {
  let url = getDomain(language) + '/download/'

  if (redirect) {
    location.href = url
  } else {
    window.open(url)
  }
}

export function gotoDownloadByDefault(language) {
  let url = getDomain(language) + '/download/'
  window.bitClientMain.openByDefaultBrowser(url)
}

// 读取通过file input 选择的文件内容
export async function getFileReaderTextResult(file) {
  if (!file) return ''

  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsText(file)
    reader.addEventListener('loadend', () => {
      resolve(reader.result)
    })
  })
}

// 获取折扣值
export function getDiscountValue(num, locale) {
  return locale === 'zh' ? parseFloat(num * 10).toFixed(1) + '折' : parseFloat(num * 100) + '%'
}

// get请求参数拼接
export function getformatQuery(obj) {
  let str = ''
  for (const key in obj) {
    str += `&${key}=${obj[key]}`
  }
  if (str) {
    str = str.slice(1)
  }
  return str
}
// 是否是移动端
export function hasMobile() {
  let isMobile = false
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    isMobile = true
  }
  if (isMobile && document.body.clientWidth <= 768) {
    isMobile = true
  }
  return isMobile
}

// 获取系统信息并上报
export async function sysInfoCollection() {
  const cookieKey = 'SYS_ENCRYPTION_TAG'
  if (Cookies.has(cookieKey)) return

  const UAParser = await import('ua-parser-js')
  const parser = new UAParser.default(navigator.userAgent)
  const result = parser.getResult()

  const webgl = getWebGLInfo()
  const speechVoices = await getSpeechVoices()
  const mediaDevices = await getMediaDevices()

  const params = {
    os: result.os.name,
    osVersion: result.os.version,
    ua: result.ua,
    browser: result.browser.name,
    browserVersion: result.browser.version,
    cpu: result.cpu.architecture,
    device: result.device.vendor,
    deviceModel: result.device.model,
    height: window.screen.height,
    width: window.screen.width,
    availWidth: window.screen.availWidth,
    availHeight: window.screen.availHeight,
    colorDepth: window.screen.colorDepth,
    ...webgl,
    speechVoices: JSON.stringify(speechVoices),
    mediaDevices: JSON.stringify(mediaDevices),
    devicePixelRatio: window.devicePixelRatio,
    font: await getEnabledFontList()
  }

  params.fingerPrintId = getHash(JSON.stringify(params))

  dataCollectionAndUpload(params).then(() => {
    Cookies.set(cookieKey, uid(), { expires: 24 * 7 })
  })
}

function getWebGLParameters(gl, parameterKeys = []) {
  const webglParameters = {}

  parameterKeys.map(item => {
    const value = gl.getParameter(gl[item])
    if (typeof value === 'number' || typeof value === 'boolean') {
      webglParameters[item] = value
    } else {
      const type = getTypedArrayType(value)
      if (type === 'Unknown') return

      webglParameters[item] = {
        type,
        value: Array.from(value)
      }
    }
  })

  return webglParameters
}

function getWebglShaderPrecisionFormats(gl) {
  const obj = {
    FRAGMENT_SHADER: {},
    VERTEX_SHADER: {}
  }

  Shader_PrecisionType.map(item => {
    obj.FRAGMENT_SHADER[item] = gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl[item])
    obj.VERTEX_SHADER[item] = gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl[item])
  })

  return JSON.stringify(obj)
}

function getWebGLInfo() {
  var canvas = document.createElement('canvas')
  var gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl')

  var debugInfo = gl.getExtension('WEBGL_debug_renderer_info')

  const canvas2 = document.createElement('canvas')
  const gl2 = canvas2.getContext('webgl2')

  const webglParameters = getWebGLParameters(gl, WebGL_Parameters)
  const webglParameters2 = getWebGLParameters(gl2, WebGL_Parameters2)

  var unmaskedVendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL)
  var unmaskedRenderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
  return {
    unmaskedVendor,
    unmaskedRenderer,
    webglParameters: JSON.stringify(webglParameters),
    webglParameters2: JSON.stringify(webglParameters2),
    shaderPrecisionFormats: getWebglShaderPrecisionFormats(gl)
  }
}

function getSpeechVoices() {
  return new Promise(resolve => {
    const speech = window.speechSynthesis
    if (speech.onvoiceschanged !== undefined) {
      speech.onvoiceschanged = () => {
        const voices = speech.getVoices()
        const voicesArr = []
        voices.forEach(item => {
          voicesArr.push({
            name: item.name,
            default: item.default,
            lang: item.lang,
            localService: item.localService,
            voiceURI: item.voiceURI
          })
        })
        resolve(voicesArr)
      }
    }
  })
}

async function getMediaDevices() {
  if (!navigator.mediaDevices) return []

  const devices = await navigator.mediaDevices.enumerateDevices()

  return devices.reduce((acc, cur) => {
    acc.push({
      deviceId: cur.deviceId === 'default' ? 'default' : '',
      kind: cur.kind,
      label: cur.label
    })
    return acc
  }, [])
}

// 加载外部js
export function loadScript(src) {
  var scriptElement = document.createElement('script')
  scriptElement.src = src
  var s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(scriptElement, s)
}

export function jsonp(url, options) {
  // 超时处理
  const { timeout } = options
  return new Promise((resolve, reject) => {
    // 防止函数名冲突
    let funcName = `jsonp${Date.now()}`
    let time = null,
      scriptNode
    // 定义callback
    window[funcName] = function (data) {
      if (timeout) clearTimeout(time)
      resolve(data)
      // 很重要的性能优化点
      // 清除本次请求产生的回调函数和script标签
      delete window[funcName]
      document.body.removeChild(scriptNode)
    }
    // 创建script标签
    scriptNode = document.createElement('script')
    // 给script标签添加src属性
    scriptNode.src = `${url}?callback=${funcName}&${options.params}`
    // 发出请求
    document.body.appendChild(scriptNode)
    time = setTimeout(() => {
      reject('network err, timeout')
    }, timeout)
    // 失败
    scriptNode.onerror = function (err) {
      reject(err)
    }
  })
}

const objectToString = Object.prototype.toString
const toTypeString = value => objectToString.call(value)
const isPlainObject = val => toTypeString(val) === '[object Object]'
export function isEmptyObject(val) {
  return isPlainObject(val) && Object.keys(val).length === 0
}
// 获取国家列表里面当前语言对应的名称
export function getLanguagesName(lang, json) {
  return lang === 'zh' ? json.lang : json[lang]
}
// 获取国家地区里面当前语言对应名称
export function getCountryListName(lang, json) {
  return lang === 'zh' ? json.cname : json[`${lang}Name`]
}

/**
 * @description 版本对比
 * @returns {number} 1 => 远程版本大，-1 => 本地版本大，0 => 版本相同
 */
export function compareVersion(curr = '', remote = '') {
  if (curr === remote) return 0
  const currStack = curr.split('.')
  const remoteStack = remote.split('.')
  while (remoteStack.length) {
    if (currStack.length === 0) return 1 // 远程版本stack长度大于curr长度时，代表远程的版本大，此时所有currStack都已经遍历完，且和remoteStack的前几项都一样
    const currItem = +currStack.shift()
    const remoteItem = +remoteStack.shift()
    if (currItem > remoteItem) return -1
    if (currItem < remoteItem) return 1
  }
  if (currStack.length > 0) return -1 // 远程版本比较完，currStack还有元素，代表curr版本大
}
// 延迟
export function sleep(seconds) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, seconds * 1000)
  })
}
